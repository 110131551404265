export const writeToLocalStorage = <T>(key: string, value: T): void => {
  const serializedValue = JSON.stringify(value);
  localStorage.setItem(key, serializedValue);
};

export const getFromLocalStorage = <T>(key: string): T | undefined => {
  const serializedValue = localStorage.getItem(key);
  if (serializedValue === null) {
    return undefined;
  }
  return JSON.parse(serializedValue) as T;
};
