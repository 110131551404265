'use client';

import { ReactNode } from 'react';
import { twMerge } from 'tailwind-merge';

export const Checkbox = ({
  value,
  onChange,
  children,
  id,
  disabled,
  className,
}: {
  children: ReactNode;
  value: boolean;
  onChange: () => void;
  id: string;
  disabled?: boolean;
  className?: string;
}) => {
  return (
    <div className={twMerge('flex items-center gap-x-2', className)}>
      <input
        id={id}
        type="checkbox"
        onChange={onChange}
        checked={value}
        disabled={disabled}
        className="w-4 h-4 bg-neutral-950 accent-blue-700 rounded"
      />
      <label htmlFor={id} className="font-sans text-sm cursor-pointer text-neutral-100">
        {children}
      </label>
    </div>
  );
};
