'use client';

import { ArrowIcon } from '@/components';
import { twMerge } from 'tailwind-merge';
import { useRouter } from 'next/navigation';
import { useLocalizedContentfulStrings } from '@/hooks/content';

export const BackButton = ({ onClick, className }: { onClick?: () => void; className?: string }) => {
  const router = useRouter();
  const { localize } = useLocalizedContentfulStrings();

  return (
    <button
      onClick={onClick ? onClick : () => router.back()}
      className={twMerge('flex font-sans font-semibold items-center gap-x-2 text-neutral-100 uppercase', className)}
    >
      <ArrowIcon className="rotate-180" size={24} />
      {localize('back')}
    </button>
  );
};
