'use client';

import { useBridgeContext } from '@/BridgeProvider';
import { Button, Checkbox } from '@/components';
import { WithdrawPath } from '@/components/ReviewWithdrawal/components/WithdrawPath';
import localConfig from '@/config';
import { useToggle } from 'react-use';
import { useAccount, useSwitchChain } from 'wagmi';
import Image from 'next/image';
import { useLocalizedContentfulStrings } from '@/hooks/content';
import { NETWORK_TOKENS } from '@/constants/tokens';

export const ReviewWithdrawalContent = ({
  onSubmit,
  isLoading,
}: {
  onSubmit: () => Promise<void | null | `0x${string}`>;
  isLoading: boolean;
}) => {
  const [confirmed, toggleConfirmed] = useToggle(false);
  const { state } = useBridgeContext();
  const { chain } = useAccount();
  const { switchChainAsync } = useSwitchChain();
  const shouldSwitchNetwork = chain?.id !== parseInt(localConfig.l2ChainId);
  const { localize } = useLocalizedContentfulStrings();
  const selectedTokenIcon = chain
    ? NETWORK_TOKENS[chain.id].find((token) => token.symbol === state.selectedCurrency)?.icon
    : '';

  function getLabel() {
    if (shouldSwitchNetwork) {
      return localize('switchNetwork');
    }

    return isLoading ? localize('waitingForConfirmation') : `${localize('initiate')} ${localize('withdrawal')}`;
  }

  return (
    <div className="flex flex-col gap-y-8 bg-neutral-950 p-8">
      <div>
        <div className="bg-neutral-1000 bg-opacity-[.56] rounded-lg p-4 border border-grey-900 flex gap-x-3 items-center mb-2">
          {selectedTokenIcon && (
            <Image
              className="rounded-full"
              src={selectedTokenIcon}
              width={32}
              height={32}
              alt={state.selectedCurrency}
            />
          )}
          <p className="text-[32px] font-sans font-semibold">
            {state.inputValue} {state.selectedCurrency}
          </p>
        </div>
        <WithdrawPath />
      </div>
      <Checkbox id="confirmWithdraw" onChange={toggleConfirmed} value={confirmed}>
        {localize('withdrawalDurationCheckboxLabel')}
      </Checkbox>
      <Button
        onClick={async () => {
          if (shouldSwitchNetwork) {
            switchChainAsync({ chainId: parseInt(localConfig.l2ChainId) });
          } else {
            onSubmit();
          }
        }}
        className="w-full flex items-center justify-center"
        disabled={!confirmed || isLoading}
      >
        {getLabel()}
      </Button>
    </div>
  );
};
