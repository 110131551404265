import { QueryKeys } from '@/constants/queryKeys';
import { useQuery } from '@tanstack/react-query';
import axios, { AxiosResponse } from 'axios';
import { ExplorerTransaction } from '@/types/expoler';

export const fetcher = async (hash?: string): Promise<ExplorerTransaction | undefined> => {
  if (!hash) return;

  try {
    const { data: transactionDetails } = await axios.get<void, AxiosResponse<ExplorerTransaction>>(
      `/api/getExplorerTransactioDetails?transaction=${hash}`
    );

    return transactionDetails;
  } catch (error) {
    console.error('error', error);
  }
};

export const useTransactionDetails = (hash?: string) => {
  return useQuery<ExplorerTransaction | undefined>({
    queryKey: [QueryKeys.TRANSACTION_DETAILS, hash],
    queryFn: async () => {
      return fetcher(hash);
    },
    enabled: !!hash,
  });
};
