'use client';

import { navLinks } from '@/components/Header/helpers';
import LanguageSwitcher from '@/components/LanguageSwitcher';
import { MobileMenu } from '@/components/MobileMenu';
import localConfig from '@/config';
import clsx from 'clsx';
import Image from 'next/image';
import Link from 'next/link';
import { usePathname } from 'next/navigation';
import { CustomRainbowButton } from '../ConnectButton';
import { DropdownNavMenu } from './components/DropdownNavMenu';

const Header = () => {
  const currentRoute = usePathname();
  const links = navLinks();

  return (
    <header className="sticky top-0 z-50 border-neutral-900 border-b-[1px] h-[80px] bg-neutral-black">
      <div className="h-full flex items-center justify-between max-w-[1200px] mx-auto">
        <div className="flex items-center h-full">
          <MobileMenu className="flex lg:hidden" />
          <Link className="shrink-0" target="_blank" href="https://mode.network/">
            <Image className="w-[132px]" src={'/mode_logo.svg'} alt="logo" width={132} height={40} priority />
          </Link>
          <div className="space-x-2 hidden lg:flex lg:ml-[64px]">
            {links.map(({ text, href, disabled, show, children }) => {
              if (show) {
                if (children && children.length > 0) {
                  return <DropdownNavMenu key={`${text}-${href}`} name={text} items={children} />;
                }
                return (
                  <Link
                    key={text}
                    href={href}
                    className={clsx(
                      disabled && 'pointer-events-none text-neutral-600',
                      currentRoute === href && 'text-yellow-500',
                      'hover:bg-yellow-500/8 hover:border-yellow-500/8',
                      'py-2 px-2 xl:px-4',
                      'text-neutral-100 font-medium border border-transparent rounded-lg uppercase text-sm xl:text-base'
                    )}
                  >
                    {text}
                  </Link>
                );
              }

              return null;
            })}
          </div>
        </div>
        <div className="flex items-center gap-4">
          {localConfig.showLanguageSwitcher === 'true' && <LanguageSwitcher />}
          <CustomRainbowButton />
        </div>
      </div>
    </header>
  );
};

export default Header;
