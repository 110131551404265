'use client';

import { createContext, FC, PropsWithChildren, useContext, useMemo } from 'react';
import { useGeoLocation } from '@/hooks/other/useGeoLocation';
import * as Dialog from '@radix-ui/react-dialog';
import Image from 'next/image';
import { RESTRICTED_COUNTRIES } from '@/app/context/LocationContext/countries';
import { useLocalizedContentfulStrings } from '@/hooks/content';

interface ILocationContext {
  isReadOnly: boolean;
}

const LocationContext = createContext<ILocationContext>({
  isReadOnly: false,
});

export function useLocationContext() {
  const context = useContext(LocationContext);

  if (context === undefined) {
    throw new Error('useLocationContext must be used withing LocationContextProvider');
  }

  return context;
}

export const LocationContextProvider: FC<PropsWithChildren> = ({ children }) => {
  const { data: query } = useGeoLocation();
  const { localize } = useLocalizedContentfulStrings();

  const showLocationWarning = (!!query?.country && RESTRICTED_COUNTRIES.includes(query.country)) ?? false;

  const contextValue = useMemo(() => {
    return {
      isReadOnly: query?.country ? RESTRICTED_COUNTRIES.includes(query?.country) : false,
    };
  }, [query?.country]);

  return (
    <LocationContext.Provider value={contextValue}>
      {children}
      <div>{showLocationWarning ? 'OPEN' : null}</div>

      <Dialog.Root open={showLocationWarning}>
        <Dialog.Portal>
          <Dialog.Overlay className="fixed inset-0 bg-black opacity-80 z-40" />
          <Dialog.Content className="z-50 bg-black text-neutral-black border border-yellow-500 fixed top-[50%] left-[50%] sm:w-unset w-screen sm:w-fit sm:min-w-[720px] translate-x-[-50%] translate-y-[-50%] p-4 flex items-center justify-center flex-col gap-[37px]">
            <div className="hidden md:block relative w-[209px] h-[219px]">
              <Image className="object-cover" sizes="100%" src="/assets/images/geo.png" fill alt="" />
            </div>
            <div className="flex flex-col items-center justify-center">
              <div className="text-[18px] text-white font-medium text-center mb-2">
                {localize('ineligibleToClaimTitle')}
              </div>
              <div className="text-base text-neutral-100 max-w-md text-center">{localize('ineligibleToClaimText')}</div>
              <Dialog.Close className="text-white m-l-auto mt-8">
                <a
                  href="https://mode.network/terms"
                  className="h-10 px-4 text-base flex items-center justify-center rounded text-neutral-black font-medium bg-yellow-500 disabled:opacity-50"
                >
                  Terms & Conditions
                </a>
              </Dialog.Close>
            </div>
            COUNTRY
          </Dialog.Content>
        </Dialog.Portal>
      </Dialog.Root>
    </LocationContext.Provider>
  );
};
