'use client';

import clsx from 'clsx';
import { useMemo } from 'react';

export interface ProgressBarProps {
  maxValue: number;
  filledValue: number;
  className?: string;
  variant?: 'primary' | 'secondary';
}

export const ProgressBar = ({ maxValue, filledValue, className, variant = 'primary' }: ProgressBarProps) => {
  const filledPercentage = useMemo(() => {
    if (!maxValue) return 0;
    return filledValue > maxValue ? 100 : (filledValue / maxValue) * 100;
  }, [maxValue, filledValue]);

  return (
    <div className={clsx(className, 'relative h-2 w-full bg-neutral-900 rounded overflow-hidden')}>
      <div
        style={{ width: `${filledPercentage}%` }}
        className={clsx('absolute h-full', {
          'bg-yellow-500': variant === 'primary',
          'bg-[#404040]': variant === 'secondary',
        })}
      />
    </div>
  );
};
