'use client';

import { AnimatePresence, motion } from 'framer-motion';
import merge from 'lodash.merge';
import Image from 'next/image';
import { useEffect, useRef, useState } from 'react';
import { usePopper } from 'react-popper';
import { twMerge } from 'tailwind-merge';
import { useOnClickOutside } from 'usehooks-ts';

type Option<T> = {
  icon?: string;
  label: string;
  value: T;
};

export const TokenSelectorDropdown = <T,>({
  options,
  onToggle,
  label,
  disabled,
  selectedValue,
  onSelect,
  className,
  optionClassName,
  children,
  hideSelector,
}: {
  options: Option<T>[];
  onToggle?: (newState: boolean) => void;
  label: string;
  disabled?: boolean;
  selectedValue: Option<T> | undefined;
  onSelect: (val: T) => void;
  className?: string;
  optionClassName?: string;
  children?: React.ReactNode;
  hideSelector?: boolean;
}) => {
  const [open, setOpen] = useState(false);
  const [referenceElement, setReferenceElement] = useState<HTMLElement | null>(null);
  const [popperElement, setPopperElement] = useState<HTMLElement | null>(null);

  function openCloseDropdown() {
    if (open) {
      setOpen(!open);
    }
  }

  const popperRef = useRef(popperElement);
  const referenceRef = useRef(referenceElement);

  useEffect(() => {
    popperRef.current = popperElement;
    referenceRef.current = referenceElement;
  }, [popperElement, referenceElement]);

  useOnClickOutside([popperRef, referenceRef], openCloseDropdown);

  const opts = merge(
    {
      modifiers: [
        {
          name: 'offset',
          options: {
            offset: [0, 8],
          },
        },
      ],
    },
    options
  );

  const { attributes } = usePopper(referenceElement, popperElement, opts);

  const handleOptionClick = (option: Option<T>) => {
    onSelect(option.value);
    setOpen(false);
  };

  useEffect(() => {
    if (onToggle) onToggle(open);
  }, [open, onToggle]);

  return (
    <div className={twMerge('inline-block w-full font-sans font-semibold text-neutral-100', className)}>
      <div className="py-4 px-2 sm:px-4 w-full bg-neutral-900 rounded-lg">
        <div className="flex flex-col items-start gap-1 w-full">
          <p className="whitespace-nowrap overflow-hidden overflow-ellipsis mb-2 uppercase">{label}</p>
          <div className="flex w-full items-center">
            {children}
            {!hideSelector && (
              <div className="w-full flex flex-col items-end">
                <button
                  ref={setReferenceElement}
                  disabled={disabled}
                  onClick={() => setOpen(!open)}
                  id="options-menu"
                  aria-haspopup="listbox"
                  className="flex bg-neutral-700 self-end rounded-lg py-2 pl-4 pr-2"
                >
                  {selectedValue?.icon && (
                    <Image src={selectedValue.icon} alt="svg" width={24} height={24} className="rounded-full mr-1" />
                  )}
                  <span className={twMerge('whitespace-nowrap mr-2', optionClassName)}>{selectedValue?.label}</span>
                  {!disabled && <Image src={'/down_arrow.svg'} alt="Open" width={24} height={24} />}
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
      <AnimatePresence>
        {open && (
          <div
            className="flex flex-col gap-y-4 p-4 w-full rounded-lg bg-neutral-900 absolute z-20 top-0 left-0"
            ref={setPopperElement}
            {...attributes.popper}
          >
            <p className="whitespace-nowrap overflow-hidden overflow-ellipsis text-neutral-100 uppercase">{label}</p>
            <motion.ul
              initial={{ opacity: 0, transform: 'translateY(20px)' }}
              animate={{ opacity: 1, transform: 'translateY(0px)' }}
              exit={{ opacity: 0 }}
              role="listbox"
              aria-labelledby="options-menu"
              aria-activedescendant="selected-option"
              className="flex flex-row flex-wrap gap-4 py-1"
            >
              {options.map((option, ix) => (
                <li
                  key={`${option.label}-${ix}`}
                  onClick={() => handleOptionClick(option)}
                  className={twMerge(
                    'cursor-pointer select-none relative px-4 py-2 flex items-center bg-neutral-700 rounded-lg',
                    optionClassName
                  )}
                >
                  <div className="flex items-center gap-x-2">
                    {option.icon && <Image src={option.icon} alt="svg" width={24} height={24} className="ml-auto" />}
                    <span className="text-neutral-100 whitespace-nowrap overflow-hidden overflow-ellipsis">
                      {option.label}
                    </span>
                  </div>
                </li>
              ))}
            </motion.ul>
          </div>
        )}
      </AnimatePresence>
    </div>
  );
};
