export const TradingIcon = ({ className, size = 24 }: { className?: string; size?: number }) => {
  return (
    <svg
      className={className}
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.75 2V10H11V19H7.75V22H6.25V19H3V10H6.25V2H7.75ZM17.75 2.00001V5H21V19H17.75V22H16.25V19H13V5H16.25V2.00001H17.75ZM14.5 6.5V17.5H19.5V6.5H14.5ZM4.5 11.5V17.5H9.5V11.5H4.5Z"
        fill="currentColor"
      />
    </svg>
  );
};
