export const MagicStickIcon = ({ className, size = 20 }: { className?: string; size?: number }) => {
  return (
    <svg
      className={className}
      width={size}
      height={size}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.4168 1.61612L18.384 4.58333L4.58343 18.3839L1.61621 15.4167L15.4168 1.61612ZM12.759 6.04167L13.9584 7.24112L16.6162 4.58333L15.4168 3.38388L12.759 6.04167ZM13.0745 8.125L11.8751 6.92555L3.38398 15.4167L4.58343 16.6161L13.0745 8.125Z"
        fill="currentColor"
      />
      <path
        d="M8.33343 1.66667L9.16676 0L10.0001 1.66667L11.6668 2.5L10.0001 3.33333L9.16676 5L8.33343 3.33333L6.66676 2.5L8.33343 1.66667Z"
        fill="currentColor"
      />
      <path
        d="M15.8334 10.8333L16.6668 9.16667L17.5001 10.8333L19.1668 11.6667L17.5001 12.5L16.6668 14.1667L15.8334 12.5L14.1668 11.6667L15.8334 10.8333Z"
        fill="currentColor"
      />
      <path
        d="M4.16676 5.83333L5.00009 4.16667L5.83343 5.83333L7.50009 6.66667L5.83343 7.5L5.00009 9.16667L4.16676 7.5L2.50009 6.66667L4.16676 5.83333Z"
        fill="currentColor"
      />
    </svg>
  );
};
