import { THIRD_PARTY_BRIDGE_ONLY_TOKENS, THIRD_PARTY_BRIDGE_ONLY_NETWORK_IDS } from '@/constants/tokens';
import { useBridgeContext, Source } from '@/BridgeProvider';
import { useMemo } from 'react';

export function useThirdPartyOnlyBridge(source: Source) {
  const { state } = useBridgeContext();
  const selectedTokenSymbol = state.selectedCurrency;
  const selectedNetworkId = state[source].network;

  const isTokenThirdPartyOnly = (tokenSymbol: string) => {
    return THIRD_PARTY_BRIDGE_ONLY_TOKENS.includes(tokenSymbol);
  };

  const isNetworkThirdPartyOnly = (networkId: number) => {
    return THIRD_PARTY_BRIDGE_ONLY_NETWORK_IDS.includes(networkId);
  };
  const isThirdPartyOnlyTokenSelected = useMemo(() => {
    return isTokenThirdPartyOnly(selectedTokenSymbol);
  }, [selectedTokenSymbol]);

  const isThirdPartyOnlyNetworkSelected = useMemo(() => {
    return isNetworkThirdPartyOnly(selectedNetworkId);
  }, [selectedNetworkId]);

  return {
    isThirdPartyOnlyTokenSelected,
    isThirdPartyOnlyNetworkSelected,
    isTokenThirdPartyOnly,
    isNetworkThirdPartyOnly,
  };
}
