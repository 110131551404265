'use client';

import Image from 'next/image';

import { ExternalLinkIcon } from '../icons';
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from '../AlertDialog';
import { AlertTriangleIcon } from '@/icons';
import { useLocalizedContentfulStrings } from '@/hooks/content';

interface WarningModalProps {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
}

export const WarningModal = ({ isOpen, onClose, onConfirm }: WarningModalProps) => {
  const { localize } = useLocalizedContentfulStrings();

  return (
    <AlertDialog open={isOpen} onOpenChange={onClose}>
      <AlertDialogContent className="flex flex-col justify-center border border-neutral-900 bg-neutral-950 rounded-2xl">
        <AlertDialogHeader className="flex justify-between p-4 bg-neutral-1000 rounded-t-xl">
          <AlertDialogTitle>
            <div className="font-chakra font-bold text-lg uppercase text-grey-500 ">
              {localize('warningModalTitle')}
            </div>
            <div className="font-mono text-xs text-grey-600 uppercase">{localize('3rdPartyApp')}</div>
          </AlertDialogTitle>
          <div onClick={onClose}>
            <Image src="/assets/icons/close.svg" width={16} height={16} alt="close icon" />
          </div>
        </AlertDialogHeader>
        <AlertDialogDescription className="flex flex-col items-center justify-center gap-4 p-4">
          <AlertTriangleIcon className="w-10 h-10 text-yellow-500" />
          <div className="w-full font-sans text-sm text-grey-600 text-center">{localize('warningModalContent')}</div>
        </AlertDialogDescription>
        <AlertDialogFooter className="w-full flex items-center p-4">
          <AlertDialogAction
            className="w-full items-center justify-center flex gap-1 border bg-yellow-500 text-neutral-black rounded-lg px-4 py-2"
            onClick={onConfirm}
          >
            <span className="font-sans font-medium text-sm uppercase">{localize('continue')}</span>
            <ExternalLinkIcon size={16} />
          </AlertDialogAction>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
};
