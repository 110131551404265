export const InfoIcon = ({ className = 'text-orange-500', size = 16 }: { className?: string; size?: number }) => {
  return (
    <svg
      className={className}
      width={size}
      height={size}
      viewBox="0 0 16 16"
      fill="curentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.0026 2.33301C4.87299 2.33301 2.33594 4.87006 2.33594 7.99967C2.33594 11.1293 4.87299 13.6663 8.0026 13.6663C11.1322 13.6663 13.6693 11.1293 13.6693 7.99967C13.6693 4.87006 11.1322 2.33301 8.0026 2.33301ZM1.33594 7.99967C1.33594 4.31778 4.32071 1.33301 8.0026 1.33301C11.6845 1.33301 14.6693 4.31778 14.6693 7.99967C14.6693 11.6816 11.6845 14.6663 8.0026 14.6663C4.32071 14.6663 1.33594 11.6816 1.33594 7.99967ZM6.66927 6.83301H8.5026L8.5026 11.333L7.5026 11.333L7.5026 7.83301H6.66927V6.83301Z"
        fill="currentColor"
      />
      <path d="M7.66406 5H8.33073V5.66667H7.66406V5Z" fill="currentColor" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.5 4.83301H8.5V5.83301H7.5V4.83301ZM7.66667 4.99967V5.66634H8.33333V4.99967H7.66667Z"
        fill="currentColor"
      />
    </svg>
  );
};
