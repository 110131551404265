'use client';

import { ReactNode } from 'react';
import { twMerge } from 'tailwind-merge';
import { CopyButton, CircleLoader } from '@/components';

export const ButtonInput = ({
  buttonLabel,
  placeholder,
  value,
  displayValue,
  onButtonClick,
  isLoading,
  error,
  onCopyValue,
  disabled,
}: {
  buttonLabel: ReactNode;
  placeholder: ReactNode;
  value?: string;
  displayValue?: string;
  onButtonClick: () => void;
  isLoading?: boolean;
  error?: string;
  onCopyValue?: () => void;
  disabled?: boolean;
}) => {
  return (
    <div>
      <div
        className={twMerge(
          'flex bg-grey-450 border rounded-lg overflow-hidden h-[42px]',
          value ? 'border-yellow-500' : 'border-grey-100'
        )}
      >
        <div className="px-3 h-full flex items-center w-full font-sans font-medium text-xs text-white uppercase">
          {displayValue ? displayValue : placeholder}
        </div>
        {value ? (
          <div className="h-full flex items-center justify-center bg-yellow-500 px-4">
            <CopyButton
              onClick={onCopyValue}
              className="text-neutral-black hover:text-neutral-black"
              copyValue={value}
            />
          </div>
        ) : (
          <button
            disabled={disabled}
            className={twMerge(
              'w-[130px] shrink-0 h-full px-4 flex items-center justify-center text-neutral-black',
              (isLoading || disabled) && 'pointer-events-none',
              disabled ? 'bg-grey-300' : 'bg-grey-100'
            )}
            onClick={onButtonClick}
          >
            {isLoading ? <CircleLoader /> : buttonLabel}
          </button>
        )}
      </div>
      {error && <p className="text-orange-500 text-xs ml-2 mt-2">{error}</p>}
    </div>
  );
};
