'use client';

import { useState } from 'react';
import Link from 'next/link';

import { WarningModal } from './WarningModal';

interface ExternalLinkWarningProps {
  href: string;
  children: React.ReactNode;
  className?: string;
}

export function ExternalLinkWarning({ href, children, className }: ExternalLinkWarningProps) {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleLinkClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    setIsModalOpen(true);
  };

  const handleConfirm = () => {
    window.open(href, '_blank', 'noopener,noreferrer');
    setIsModalOpen(false);
  };

  return (
    <>
      <Link rel="noopener noreferrer" target="_blank" href={href} onClick={handleLinkClick} className={className}>
        {children}
      </Link>
      <WarningModal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} onConfirm={handleConfirm} />
    </>
  );
}
