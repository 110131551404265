import { TooltipRenderProps } from 'react-joyride';
import Image from 'next/image';

export const Tooltip = (props: TooltipRenderProps) => {
  const { continuous, index, size, step, backProps, closeProps, skipProps, primaryProps, tooltipProps, isLastStep } =
    props;

  return (
    <div {...tooltipProps} className="relative p-4 bg-yellow-500 text-neutral-black max-w-xs rounded-md">
      {size > 1 && <div className="text-xs font-medium">{`${index + 1}/${size}`}</div>}
      <div className="flex flex-col gap-1 mb-2">
        {step.title && <div className="text-sm font-bold leading-tight">{step.title}</div>}
        <div className="text-xs font-medium leading-tight">{step.content}</div>
      </div>
      <div className="flex flex-row justify-between">
        {!isLastStep && (
          <button {...skipProps} className="text-xs font-medium leading-normal">
            Skip
          </button>
        )}
        <div className="flex flex-row justify-end gap-4">
          {index > 0 && (
            <button {...backProps} className="text-xs font-medium leading-normal">
              Back
            </button>
          )}
          {continuous && (
            <button
              {...primaryProps}
              className="px-2 py-1 rounded border text-xs font-medium leading-tight text-center uppercase"
            >
              Next
            </button>
          )}
          {size > 1 && !continuous && (
            <button
              {...closeProps}
              className="px-2 py-1 rounded border text-xs font-medium leading-tight text-center uppercase"
            >
              {index + 1 < size ? 'Next' : 'Close'}
            </button>
          )}
          <button {...closeProps} className="absolute top-2 right-2 p-2 rounded bg-transparent">
            <Image src="/assets/icons/close_secondary.svg" alt="Close" width={16} height={16} />
          </button>
        </div>
      </div>
    </div>
  );
};
