import type { SVGProps } from 'react';
import { Ref, forwardRef } from 'react';
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgAlertTriangleIcon = (
  { title, titleId, strokeWidth = 2, ...props }: SVGProps<SVGSVGElement> & SVGRProps,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-hidden="true"
    ref={ref}
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      d="M10 10.809V6.77701M10 13.7975V13.8329M15.0399 17.1909H4.96006C3.5832 17.1909 2.42127 16.2707 2.05569 15.0118C1.89964 14.4744 2.09139 13.9146 2.38467 13.438L7.42461 4.24808C8.60542 2.32925 11.3946 2.32925 12.5754 4.24808L17.6153 13.438C17.9086 13.9146 18.1004 14.4744 17.9443 15.0118C17.5787 16.2707 16.4168 17.1909 15.0399 17.1909Z"
      stroke="currentColor"
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
const ForwardRef = forwardRef(SvgAlertTriangleIcon);
export default ForwardRef;
