import { ButtonHTMLAttributes, ForwardedRef, forwardRef, ReactNode } from 'react';
import { twMerge } from 'tailwind-merge';

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  className?: string;
  icon?: ReactNode;
  size?: 'md' | 'sm';
  filled?: boolean;
  rounded?: boolean;
  variant?: 'primary' | 'secondary';
}

export const Button = forwardRef(
  (
    { className, icon, children, filled = true, rounded, size = 'md', variant = 'primary', ...rest }: ButtonProps,
    ref: ForwardedRef<HTMLButtonElement>
  ) => {
    return (
      <button
        className={twMerge(
          size === 'md' && 'h-10 px-4 text-base',
          size === 'sm' && 'h-8 px-2 text-xs',
          filled
            ? variant === 'primary'
              ? 'bg-yellow-500 text-neutral-black'
              : 'bg-neutral-900 text-neutral-100'
            : 'border border-yellow-500 bg-yellow-500/[.08] text-yellow-500',
          rounded ? 'rounded-full' : 'rounded-lg',
          'flex items-center justify-center font-medium disabled:opacity-40 uppercase',
          className
        )}
        {...rest}
        ref={ref}
      >
        {children}
        {icon && <span>{icon}</span>}
      </button>
    );
  }
);

Button.displayName = 'Button';

export default Button;
