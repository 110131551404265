import localConfig from '@/config';
import { QueryKeys } from '@/constants/queryKeys';
import { contentfulClient } from '@/utils';
import { useQuery } from '@tanstack/react-query';
import { NewsItemEntry, NewsItemSkeleton, ImageWithTranslationsSkeleton } from '../types';
import axios, { AxiosResponse } from 'axios';
import { baseLocale, Locale } from '@/app/context';

const fetchNewsItems = async () => {
  try {
    let content = [];

    if (localConfig.showContentLive) {
      content = (
        await contentfulClient.withAllLocales.getEntries<NewsItemSkeleton, Locale>({
          content_type: 'news',
        })
      ).items;
    } else {
      content = (await axios.get<void, AxiosResponse<NewsItemEntry[]>>(`${localConfig.contentfulUrl}/news.json`)).data;
    }

    return content
      .sort((a, b) => new Date(b.sys.createdAt).getTime() - new Date(a.sys.createdAt).getTime())
      .slice(0, 5)
      .map((entry) => {
        const sysId = entry?.sys?.id || '';
        const { id, category, image, link, previewText, title, isSelectedUsersGroup } = entry.fields;

        return {
          id: String(id?.[baseLocale] ?? sysId),
          image: `https:${(image?.[baseLocale] as unknown as ImageWithTranslationsSkeleton)?.fields?.file?.[baseLocale]?.url}`,
          link: link?.[baseLocale],
          category: category?.[baseLocale] ?? '',
          isSelectedUsersGroup: isSelectedUsersGroup?.[baseLocale] ?? false,
          previewText,
          title,
        };
      });
  } catch (e) {
    console.error(e);
    return;
  }
};

export const useNewsItems = (options = {}) => {
  return useQuery({
    queryKey: [QueryKeys.NEWS],
    queryFn: fetchNewsItems,
    ...options,
  });
};

export type INewsItem = NonNullable<Awaited<ReturnType<typeof fetchNewsItems>>>[number];
