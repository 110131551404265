import { ReactNode } from 'react';
import clsx from 'clsx';
import { twMerge } from 'tailwind-merge';

export const SectionContainer = ({ children, className }: { children: ReactNode; className?: string }) => {
  return <div className={twMerge('rounded-2xl bg-neutral-950 p-4 flex flex-col', className)}>{children}</div>;
};

export const HeaderSectionBox = ({ children, className }: { children: ReactNode; className?: string }) => {
  return <div className={clsx(className, 'rounded bg-neutral-900 p-2 flex flex-col')}>{children}</div>;
};

export const PageTitle = ({ children }: { children: string }) => {
  return (
    <h1 className={`font-chakra text-neutral-100 uppercase text-[28px] md:text-[48px] font-bold mb-4 md:mb-8`}>
      {children}
    </h1>
  );
};

export const SectionTitle = ({ children, className }: { children: ReactNode; className?: string }) => {
  return (
    <h4 className={twMerge('font-chakra text-[28px] font-medium text-neutral-100 uppercase', className)}>{children}</h4>
  );
};

export const SectionDesc = ({ children, className }: { children: ReactNode; className?: string }) => {
  return <h6 className={clsx(className, 'text-xs font-medium uppercase')}>{children}</h6>;
};

export const Paragraph = ({ children }: { children: ReactNode }) => {
  return <p className="block text-[16px] text-neutral-500 mb-4">{children}</p>;
};
