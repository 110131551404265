'use client';

import { SectionTitle } from './SectionTitle';
import { useLocalizedContentfulStrings } from '@/hooks/content';
import Image from 'next/image';
import { BridgeView } from '@/utils/thirdPartyBridges/types';
import Link from 'next/link';

export const ModeCanonicalBridgeSection = ({ view }: { view: BridgeView }) => {
  const { localize } = useLocalizedContentfulStrings();

  return (
    <div>
      <SectionTitle className="mb-2">
        <Image width={24} height={24} src="/assets/icons/mode_white.svg" alt={localize('modeCanonicalBridgeTitle')} />
        {localize('modeCanonicalBridgeTitle')}
      </SectionTitle>
      <p className="font-sans text-sm text-neutral-100">
        {view === BridgeView.DEPOSIT ? (
          localize('modeCanonicalBridgeDepositDesc')
        ) : (
          <>
            {localize('modeCanonicalBridgeWithdrawDesc')}{' '}
            <Link
              className="underline"
              href="https://docs.optimism.io/builders/app-developers/bridging/messaging#for-l2-to-l1-transactions"
              target="_blank"
              title={localize('why')}
            >
              {localize('why')}
            </Link>
          </>
        )}
      </p>
    </div>
  );
};
