'use client';

import { ButtonInput, MagicStickIcon, Warning, ArrowIcon } from '@/components';
import { useLocalizedContentfulStrings } from '@/hooks/content';
import { useGlobalAddress } from '@/hooks/bridge/useGlobalAddress';
import { useMediaQuery } from 'react-responsive';
import { useMemo, useState } from 'react';
import { formatWalletAddress } from '@/utils';
import { useRouter } from 'next/navigation';
import { useAccount } from 'wagmi';
import Link from 'next/link';

export const GlobalAddressGenerationInput = ({ isGlobalAddressPage }: { isGlobalAddressPage: boolean }) => {
  const router = useRouter();
  const { localize } = useLocalizedContentfulStrings();
  const { address, generateGlobalAddress, globalAddressIsLoading, error } = useGlobalAddress();
  const isMobile = useMediaQuery({ maxWidth: 720 });
  const { address: owner } = useAccount();
  const [isWarningShown, setIsWarningShown] = useState(false);

  const errorMessage = useMemo(() => {
    if (!owner) return localize('connectWalletToGenerateGlobalAddress');
    if (error) return error;
    return '';
  }, [error, owner, localize]);

  const globalAddress = useMemo(() => {
    return isMobile ? formatWalletAddress(address, 12, 12) : address;
  }, [address, isMobile]);

  return (
    <div className="flex flex-col gap-y-4">
      <div>
        <ButtonInput
          placeholder={
            <div className="flex items-end">
              <p className="text-neutral-100">{localize('createGlobalAddress')}</p>
              <span className="ml-2 text-grey-300 font-normal normal-case">({localize('free')})</span>
            </div>
          }
          buttonLabel={
            <div className="flex items-center gap-x-2 text-neutral-black font-bold">
              {localize('generate')}
              <MagicStickIcon />
            </div>
          }
          onButtonClick={async () => {
            await generateGlobalAddress({
              onSuccess: isGlobalAddressPage ? undefined : () => router.push('/bridge/global-address'),
            });
          }}
          value={address}
          displayValue={globalAddress}
          isLoading={globalAddressIsLoading}
          error={errorMessage}
          onCopyValue={() => setIsWarningShown(true)}
          disabled={!owner}
        />
        {address && !isGlobalAddressPage && (
          <Link className="flex items-center text-white text-[10px] font-medium mt-2" href="/bridge/global-address">
            {localize('globalAddressModePageLinkTitle')}
            <ArrowIcon size={12} className="ml-1" />
          </Link>
        )}
      </div>
      {isWarningShown && <Warning text={localize('globalAddressCopyWarning')} />}
    </div>
  );
};
