export const GasIcon = ({ className, size = 12 }: { className?: string; size?: number }) => {
  return (
    <svg
      className={className}
      width={size}
      height={size}
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.5 1.5H7.5V4.5H9.25V8.25H10.25V4.16701L8.8454 2.90287L9.34713 2.3454L11 3.83299V9H8.5V5.25H7.5V9.75H8V10.5H1V9.75H1.5V1.5ZM2.25 9.75H6.75V2.25H2.25V9.75ZM3 4.5H6V5.25H3V4.5Z"
        fill="currentColor"
      />
    </svg>
  );
};
