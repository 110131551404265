import axios, { AxiosResponse } from 'axios';
import { XLinkSDK } from '@xlink-network/xlink-sdk';
import {
  AcrossSupportedRoute,
  FreeTechSupportedRoute,
  StargateSupportedChain,
  StargateSupportedToken,
  BridgeSupportedRoutes,
  BridgeView,
  SupportedRoute,
} from '@/utils/thirdPartyBridges/types';
import { getSuppostedChainIdByName, isChainSupportedByMode } from '@/utils';

const xlinkSDK = new XLinkSDK();

interface ThirdBridgeIntegration {
  fetchSupportedRoutes(): Promise<BridgeSupportedRoutes>;
}

class StargateIntegration implements ThirdBridgeIntegration {
  async fetchSupportedRoutes(): Promise<BridgeSupportedRoutes> {
    const chains = (
      await axios.get<void, AxiosResponse<{ chains: StargateSupportedChain[] }>>(
        'https://stargate.finance/api/v1/chains'
      )
    ).data.chains.map((chain) => chain.nativeChainId);
    const tokens = (
      await axios.get<void, AxiosResponse<{ tokens: StargateSupportedToken[] }>>(
        'https://stargate.finance/api/v1/tokens'
      )
    ).data.tokens
      .filter((token) => token.isBridgeable)
      .map((token) => token.symbol);

    const result: SupportedRoute[] = [];
    // form unique pairs of chainId and token
    for (const chainId of chains) {
      if (isChainSupportedByMode(chainId)) {
        for (const token of tokens) {
          if (!result.find((route) => route.chainId === chainId && route.token === token)) {
            result.push({
              chainId,
              token,
            });
          }
        }
      }
    }

    return { [BridgeView.WITHDRAW]: result, [BridgeView.DEPOSIT]: result };
  }
}

class XLinkIntegration implements ThirdBridgeIntegration {
  async fetchSupportedRoutes() {
    const result = await xlinkSDK.getSupportedRoutes();

    return result.reduce(
      (acc, cur) => {
        const depositTokenName = cur.fromToken.split('-')[1];
        const withdrawTokenName = cur.toToken.split('-')[1];
        const fromChain = getSuppostedChainIdByName(cur.fromChain.split('-')[1]);
        const toChainId = getSuppostedChainIdByName(cur.toChain.split('-')[1]);

        if (
          fromChain &&
          !acc.deposit.find((route) => route.chainId === fromChain && route.token === depositTokenName)
        ) {
          acc.deposit.push({
            chainId: fromChain,
            token: depositTokenName,
          });
        }
        if (
          toChainId &&
          !acc.withdraw.find((route) => route.chainId === toChainId && route.token === withdrawTokenName)
        ) {
          acc.withdraw.push({
            chainId: toChainId,
            token: withdrawTokenName,
          });
        }
        return acc;
      },
      { [BridgeView.WITHDRAW]: [], [BridgeView.DEPOSIT]: [] } as BridgeSupportedRoutes
    );
  }
}

class XAcrossIntegration implements ThirdBridgeIntegration {
  async fetchSupportedRoutes() {
    const result = (
      await axios.get<void, AxiosResponse<AcrossSupportedRoute[]>>('https://app.across.to/api/available-routes')
    ).data;

    return result.reduce(
      (acc, cur) => {
        if (
          isChainSupportedByMode(cur.originChainId) &&
          !acc.deposit.find((route) => route.chainId === cur.originChainId && route.token === cur.originTokenSymbol)
        ) {
          acc.deposit.push({
            chainId: cur.originChainId,
            token: cur.originTokenSymbol,
          });
        }
        if (
          isChainSupportedByMode(cur.destinationChainId) &&
          !acc.withdraw.find(
            (route) => route.chainId === cur.destinationChainId && route.token === cur.destinationTokenSymbol
          )
        ) {
          acc.withdraw.push({
            chainId: cur.destinationChainId,
            token: cur.destinationTokenSymbol,
          });
        }
        return acc;
      },
      { [BridgeView.WITHDRAW]: [], [BridgeView.DEPOSIT]: [] } as BridgeSupportedRoutes
    );
  }
}

class FreeTechIntegration implements ThirdBridgeIntegration {
  async fetchSupportedRoutes() {
    const result = (
      await axios.get<void, AxiosResponse<{ result: FreeTechSupportedRoute[] }>>('https://relayer.meson.fi/api/v1/list')
    ).data.result;

    return result.reduce(
      (acc, cur) => {
        const chainId = parseInt(cur.chainId, 16);
        if (isChainSupportedByMode(chainId)) {
          cur.tokens.forEach((token) => {
            if (!acc.deposit.find((route) => route.chainId === chainId && route.token === token.id)) {
              acc.deposit.push({
                chainId: chainId,
                token: token.id,
              });
            }
          });
          acc.withdraw = acc.deposit;
        }
        return acc;
      },
      { [BridgeView.WITHDRAW]: [], [BridgeView.DEPOSIT]: [] } as BridgeSupportedRoutes
    );
  }
  data?: FreeTechSupportedRoute[];
}

export const getThirdPartyBridgeIntegration = (bridgeName: string): ThirdBridgeIntegration | null => {
  switch (bridgeName.toLowerCase()) {
    case 'stargate':
      return new StargateIntegration();
    case 'xlink':
      return new XLinkIntegration();
    case 'across':
      return new XAcrossIntegration();
    case 'free.tech':
      return new FreeTechIntegration();
    default:
      return null;
  }
};
