import { ethers } from 'ethers';
import erc20_json from '@/constants/abis/ERC20.json';
import localConfig from '@/config';

export const readContractFunctionMode = async (
  contractAddress: string,
  functionName: string,
  args?: Record<string, unknown>
) => {
  const rpcProvider = new ethers.providers.JsonRpcProvider(localConfig.l2Rpc);
  const contract = new ethers.Contract(contractAddress, erc20_json.abi, rpcProvider);

  try {
    return args ? await contract[functionName](args) : await contract[functionName]();
  } catch (error) {
    console.error(error);
  }
};
