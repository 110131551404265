import { ReactNode } from 'react';
import { twMerge } from 'tailwind-merge';

export const SectionTitle = ({ children, className }: { children: ReactNode; className?: string }) => {
  return (
    <h3
      className={twMerge(
        'flex items-center flex-row font-sans gap-x-2 text-neutral-100 font-semibold text-base uppercase',
        className
      )}
    >
      {children}
    </h3>
  );
};
