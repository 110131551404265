'use client';

import { twMerge } from 'tailwind-merge';
import { InfoIcon } from '@/components';
import { useLocalizedContentfulStrings } from '@/hooks/content';

export const Warning = ({
  text,
  className,
  icon,
  iconWidth = 24,
  iconHeight = 24,
}: {
  text?: string;
  className?: string;
  icon?: string;
  iconWidth?: number;
  iconHeight?: number;
}) => {
  const { localize } = useLocalizedContentfulStrings();
  return (
    <div
      className={twMerge(
        'flex items-center justify-center font-sans gap-x-4 py-4 px-3 border border-orange-500 text-orange-500 rounded-lg text-sm bg-orange-500 bg-opacity-[4%]',
        className
      )}
    >
      <InfoIcon className="shrink-0" size={20} />
      <p>
        <span className="font-semibold">{localize('warning')}</span>: {text}
      </p>
    </div>
  );
};
