export const ArrowIcon = ({ className, size = 24 }: { className?: string; size?: number }) => {
  return (
    <svg
      className={className}
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14 4.68945L21.3107 12.0001L14 19.3108L12.9393 18.2501L18.4393 12.7501H3V11.2501H18.4393L12.9393 5.75011L14 4.68945Z"
        fill="currentColor"
      />
    </svg>
  );
};
