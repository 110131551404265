'use client';

import { ReactNode } from 'react';
import { ClaimIcon, InitiateWithdrawalIcon, ProveIcon, WaitIcon, Divider } from '@/components';
import { CalendarIcon } from '@/icons';
import { useLocalizedContentfulStrings } from '@/hooks/content';

export const WithdrawPath = () => {
  const { localize } = useLocalizedContentfulStrings();
  const renderRow = (label: string, icon: ReactNode, time?: string) => (
    <>
      <div className="flex justify-between items-center gap-x-4 font-sans font-medium p-4">
        <div className="flex items-center gap-x-4">
          {icon}
          {label}
        </div>
        <p>{time}</p>
      </div>
      <Divider type="horizontal" />
    </>
  );

  return (
    <div className="text-grey-100">
      <p className="bg-grey-900 text-sm font-semibold uppercase px-4 py-3 rounded-lg">{localize('steps')}</p>
      {renderRow('Initiate Withdrawal', <InitiateWithdrawalIcon />, '~1 min')}
      {renderRow('Wait 1 hour', <WaitIcon />)}
      {renderRow('Prove on Ethereum', <ProveIcon />, '~4 min')}
      {renderRow('Wait 7 days', <CalendarIcon className="w-4 h-4" />)}
      {renderRow('Get 0.01 ETH on Ethereum', <ClaimIcon />, '~4 min')}
    </div>
  );
};
