'use client';

import { useBridgeContext, Source } from '@/BridgeProvider';
import { Spinner, BridgeIcon } from '@/components';
import { SectionTitle } from '@/components/Bridge/SectionTitle';
import {
  BridgeOption,
  BridgeOptionCompact,
  BridgeOptionExpanded,
  format3rdPartyLink,
} from '@/components/Bridge/BridgeOption';
import { ThirdPartyBridge, useThirdPartyBridges } from '@/hooks/content';
import { useMemo } from 'react';
import { BridgeView } from '@/utils/thirdPartyBridges/types';
import { shouldShowThirdPartyBridge } from '@/utils/bridge';
import { useThirdPartyOnlyBridge } from '@/hooks/bridge/useThirdPartyOnlyBridge';
import { useLocalizedContentfulStrings } from '@/hooks/content';
import { useRouter } from 'next/navigation';

interface ThirdPartyBridgesProps {
  thirdPartyBridges: ThirdPartyBridge[];
}

export const ThirdPartyBridges = ({ thirdPartyBridges }: ThirdPartyBridgesProps) => {
  const { state } = useBridgeContext();

  return (
    <div className="uppercase text-neutral-600 text-xs">
      <div className="flex gap-x-7">
        <div className="grid grid-cols-2 sm:grid-cols-3 w-full gap-2">
          {thirdPartyBridges?.map((bridge) => (
            <BridgeOptionCompact
              key={bridge.id}
              name={bridge.name}
              logoSrc={bridge.logoSrc}
              link={format3rdPartyLink(bridge.link, state)}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

interface BridgeOptionsProps {
  view: BridgeView;
}

export function BridgeOptions({ view }: BridgeOptionsProps) {
  const source = Source.FROM;
  const { state } = useBridgeContext();
  const { data: bridges, isLoading: isThirdPartyBridgesLoading } = useThirdPartyBridges();
  const { isThirdPartyOnlyTokenSelected, isThirdPartyOnlyNetworkSelected } = useThirdPartyOnlyBridge(source);
  const { localize } = useLocalizedContentfulStrings();
  const router = useRouter();
  const selectedNetworkId = state[source].network;
  const selectedToken = state[source].token;

  const thirdPartyBridges = useMemo(() => {
    if (!bridges) return [];

    return bridges.filter(
      (bridge) =>
        shouldShowThirdPartyBridge({
          supportedRoutes: bridge.supportedRoutes,
          view,
          selectedChainId: selectedNetworkId,
          selecteTokenSymbol: selectedToken.symbol,
        }) && bridge?.name?.toLowerCase() !== 'superbridge'
    );
  }, [bridges, view, selectedNetworkId, selectedToken.symbol]);

  const superbridge = useMemo(
    () => bridges?.find((bridge) => bridge?.name?.toLowerCase() === 'superbridge'),
    [bridges]
  );

  const isSuperBridgeShown = useMemo(
    () =>
      superbridge &&
      shouldShowThirdPartyBridge({
        supportedRoutes: superbridge.supportedRoutes,
        view,
        selectedChainId: selectedNetworkId,
        selecteTokenSymbol: selectedToken.symbol,
      }),
    [superbridge, view, selectedNetworkId, selectedToken.symbol]
  );

  return (
    <div className="flex flex-col gap-y-2">
      <SectionTitle>
        <BridgeIcon />
        {localize('usingBridgeTitle')}
      </SectionTitle>
      {view === BridgeView.DEPOSIT && (
        <BridgeOption
          disabled={isThirdPartyOnlyTokenSelected || isThirdPartyOnlyNetworkSelected}
          name="Mode Canonical Bridge"
          logoSrc="/assets/icons/mode_white.svg"
          transactionEstimatedTimeDeposit="~4 mins"
          transactionEstimatedTimeWithdrawal="~7 days"
          onClick={() => router.push(`/bridge/canonical?token=${selectedToken.symbol}&network=${selectedNetworkId}`)}
        />
      )}
      {isThirdPartyBridgesLoading ? (
        <Spinner className="m-4" />
      ) : (
        <>
          {superbridge && isSuperBridgeShown && (
            <BridgeOptionExpanded
              logoSrc={superbridge.logoSrc}
              link={superbridge.link}
              buttonText="Bridge Via Superbridge"
            />
          )}
          {thirdPartyBridges.length > 0 && <ThirdPartyBridges thirdPartyBridges={thirdPartyBridges} />}
        </>
      )}
    </div>
  );
}
