'use client';

import { useBridgeContext, Source } from '@/BridgeProvider';
import { TokenSelector } from '@/components/Bridge';
import { twMerge } from 'tailwind-merge';
import { WalletIcon, GasIcon } from '@/components/icons';
import { useEstimateGas } from '@/hooks/bridge/useEstimateGas';

export const InputSection = ({ className }: { className?: string }) => {
  const { state, dispatch } = useBridgeContext();
  const source = Source.FROM;
  const layer = state.view === 'deposit' ? 'L1_balances' : 'L2_balances';
  const isBalanceExceeded = +state[layer][state.selectedCurrency] < +state.inputValue;
  const availableBalance = state[layer]?.[state.selectedCurrency];
  const { value: estimatedGas } = useEstimateGas();

  return (
    <div className={twMerge('w-full text-neutral-500', className)}>
      <div className="flex flex-col gap-y-2">
        <TokenSelector source={source} />
        <div className="h-4 w-full flex justify-between text-[10px] font-medium font-sans text-neutral-100">
          {!!availableBalance && (
            <button
              onClick={() => {
                dispatch({
                  type: 'SET_VALUE',
                  payload: estimatedGas?.eth ? Number(availableBalance) - estimatedGas?.eth : availableBalance,
                });
              }}
              className="flex gap-x-1 items-center w-full text-right"
            >
              <WalletIcon />
              {`${availableBalance} ${state.selectedCurrency}`}
            </button>
          )}
          {estimatedGas && (
            <p className="flex justify-end gap-x-1 items-center w-full text-right">
              <GasIcon />~{estimatedGas?.eth} ETH
            </p>
          )}
        </div>
      </div>
      {isBalanceExceeded && (
        <div className="flex items-center mt-1">
          <div className="text-orange-700 text-xs">Amount exceeds available balance</div>
        </div>
      )}
    </div>
  );
};
