import localConfig from '@/config';
import { QueryKeys } from '@/constants/queryKeys';
import { contentfulClient } from '@/utils';
import { useQuery } from '@tanstack/react-query';
import axios, { AxiosResponse } from 'axios';
import { ThirdPartyBridgeEntry, ThirdPartyBridgeSkeleton } from '../types';
import { getThirdPartyBridgeIntegration } from '@/utils';

const fetchThirdPartyBridges = async () => {
  try {
    let content = [];

    if (localConfig.showContentLive) {
      content = (
        await contentfulClient.getEntries<ThirdPartyBridgeSkeleton>({
          content_type: 'thirdPartyBridge',
        })
      ).items;
    } else {
      content = (
        await axios.get<void, AxiosResponse<ThirdPartyBridgeEntry[]>>(
          `${localConfig.contentfulUrl}/thirdPartyBridges.json`
        )
      ).data;
    }

    const bridges = await Promise.all(
      content.map(async (entry) => {
        const { name, id, logo, link, transactionEstimatedTimeDeposit, transactionEstimatedTimeWithdrawal } =
          entry.fields;

        const integration = getThirdPartyBridgeIntegration(name);
        const supportedRoutes = await integration?.fetchSupportedRoutes();

        return {
          name,
          id,
          link,
          logoSrc: logo?.fields?.file ? `https:${logo.fields.file.url}` : '',
          transactionEstimatedTimeDeposit,
          transactionEstimatedTimeWithdrawal,
          supportedRoutes,
        };
      })
    );
    return bridges;
  } catch (e) {
    console.error(e);
    return;
  }
};

export const useThirdPartyBridges = (options = {}) => {
  return useQuery({
    queryKey: [QueryKeys.THIRD_PARTY_BRIDGES],
    queryFn: fetchThirdPartyBridges,
    refetchOnWindowFocus: localConfig.showContentLive,
    ...options,
  });
};

export type ThirdPartyBridge = NonNullable<Awaited<ReturnType<typeof fetchThirdPartyBridges>>>[number];
