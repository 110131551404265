export const ProveIcon = ({ className, size = 24 }: { className?: string; size?: number }) => {
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3 3.5H21V12.5H19.5V5H4.5V20H12V21.5H3V3.5ZM11.5896 8.10224L8.84226 11.7653L6.50289 10.2058L7.33494 8.95769L8.49365 9.73016L10.3896 7.20224L11.5896 8.10224ZM13.0579 8.75H17.0579V10.25H13.0579V8.75ZM11.5896 14.1034L8.84226 17.7665L6.50289 16.2069L7.33494 14.9588L8.49365 15.7313L10.3896 13.2034L11.5896 14.1034ZM18.841 16.1517C17.9623 15.273 16.5377 15.273 15.659 16.1517C14.7803 17.0303 14.7803 18.455 15.659 19.3336C16.5377 20.2123 17.9623 20.2123 18.841 19.3336C19.7197 18.455 19.7197 17.0303 18.841 16.1517ZM14.5983 15.091C16.0628 13.6265 18.4372 13.6265 19.9017 15.091C21.1845 16.3738 21.3436 18.3549 20.3789 19.8109L22.3107 21.7426L21.25 22.8033L19.3183 20.8716C17.8622 21.8362 15.8812 21.6771 14.5983 20.3943C13.1339 18.9298 13.1339 16.5555 14.5983 15.091Z"
        fill="currentColor"
      />
    </svg>
  );
};
