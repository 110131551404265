'use client';

import { useEffect } from 'react';
import { useBridgeContext } from '@/BridgeProvider';
import Image from 'next/image';
import { twMerge } from 'tailwind-merge';
import { useBridgeRecipient } from '@/hooks/bridge/useBridgeRecipient';
import { useToggle } from 'react-use';
import { useLocalizedContentfulStrings } from '@/hooks/content';
import { Checkbox } from '@/components';

export const RecepientSection = ({ className }: { className?: string }) => {
  const [showInput, toggleShoeInput] = useToggle(false);
  const { state, dispatch } = useBridgeContext();
  const { localize } = useLocalizedContentfulStrings();
  const { isRecipientRequired, isRecipientInputValid } = useBridgeRecipient();
  const isMissingRecipient = isRecipientRequired && !state.recipientValue;

  useEffect(() => {
    if (isRecipientRequired) {
      toggleShoeInput(true);
    }
  }, [isRecipientRequired, toggleShoeInput]);

  return (
    <div className={twMerge('w-full text-xs text-neutral-100 mt-2 font-sans', className)}>
      <Checkbox id="confirmWithdraw" onChange={toggleShoeInput} value={showInput} disabled={isRecipientRequired}>
        {`${localize('recipientAddressCheckboxLabel')} ${isRecipientRequired ? '*' : ''}`}
      </Checkbox>
      {showInput && (
        <div className="flex flex-col gap-y-2 mt-4">
          <input
            type="text"
            className={twMerge(
              'text-neutral-100 bg-grey-450 border-grey-400 rounded-lg outline-none py-3 px-4 border w-full text-sm sm:text-base min-h-[48px]',
              (isMissingRecipient || !isRecipientInputValid) && 'border-orange-700'
            )}
            placeholder="0x..."
            value={state.recipientValue.toString()}
            onChange={(e) => {
              const val = e.target.value;

              dispatch({
                type: 'SET_RECIPIENT_VALUE',
                payload: val,
              });
            }}
          />
          {isMissingRecipient && (
            <div className="flex items-center mt-1">
              <Image src={'/assets/icons/warning.svg'} alt="Insufficient funds available" width={16} height={16} />
              <div className="text-orange-500 text-xs ml-2">{localize('reguired')}</div>
            </div>
          )}
          {!isRecipientInputValid && (
            <div className="flex items-center mt-1">
              <div className="text-orange-700 text-xs">{localize('invalidWalletAddress')}</div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};
