'use client';

import Image from 'next/image';
import { ThirdPartyBridge } from '@/hooks/content';
import { useBridgeContext, AppState } from '@/BridgeProvider';
import { ExternalLinkWarning, Button, ExternalLinkIcon, ArrowIcon } from '@/components';
import { twMerge } from 'tailwind-merge';
import { ReactElement } from 'react';
import { BridgeView } from '@/utils/thirdPartyBridges/types';

const BridgeOptionContainer = ({
  children,
  onClick,
  className,
}: {
  children: ReactElement;
  onClick?: () => void;
  className?: string;
}) => {
  return (
    <div
      role="button"
      onClick={onClick}
      className={twMerge(
        'w-full px-3 py-2 bg-neutral-900 rounded-lg border border-neutral-700 flex justify-between items-center',
        className
      )}
    >
      {children}
    </div>
  );
};

export const BridgeOption = ({
  logoSrc,
  name,
  transactionEstimatedTimeDeposit,
  transactionEstimatedTimeWithdrawal,
  disabled,
  onClick,
}: Omit<ThirdPartyBridge, 'link' | 'id' | 'supportedRoutes'> & {
  disabled?: boolean;
  onClick?: () => void;
}) => {
  const { state } = useBridgeContext();

  return (
    <BridgeOptionContainer className={twMerge(disabled && 'pointer-events-none opacity-40')} onClick={onClick}>
      <>
        <div className="flex items-center gap-x-2 font-sans">
          <Image className="saturate-0" src={logoSrc} width={20} height={20} alt={name} />
          <p className="text-neutral-100 text-xs font-medium uppercase">{name}</p>
          <p className="text-xs">
            {state.view === BridgeView.DEPOSIT
              ? `(Deposit time: ${transactionEstimatedTimeDeposit})`
              : `(Withdrawal time: ${transactionEstimatedTimeWithdrawal}`}
          </p>
        </div>
        <ArrowIcon className="text-neutral-100" />
      </>
    </BridgeOptionContainer>
  );
};

export const BridgeOptionCompact = ({ logoSrc, link, name }: { logoSrc: string; link: string; name: string }) => {
  return (
    <ExternalLinkWarning href={link} className="flex flex-col items-center w-full">
      <BridgeOptionContainer>
        <>
          <div className="flex items-center">
            <Image className="saturate-0" src={logoSrc} width={24} height={24} alt={name} />
            <p className="ml-2 text-neutral-100 font-sans font-medium">{name}</p>
          </div>
          <ExternalLinkIcon />
        </>
      </BridgeOptionContainer>
    </ExternalLinkWarning>
  );
};

export const BridgeOptionExpanded = ({
  logoSrc,
  link,
  name,
  buttonText,
}: {
  logoSrc: string;
  link: string;
  name?: string;
  buttonText: string;
}) => {
  return (
    <ExternalLinkWarning href={link} className="flex flex-col items-center">
      <BridgeOptionContainer className="p-4">
        <div className="w-full flex flex-col gap-y-4">
          <div className="flex justify-center w-full">
            <Image className="w-auto saturate-0" src={logoSrc} width={24} height={24} alt={name || ''} />
            {name ? <p className="mt-2 text-neutral-100">{name}</p> : null}
          </div>
          <Button className="w-full" filled={false}>
            {buttonText}
            <ExternalLinkIcon size={16} className="ml-2" />
          </Button>
        </div>
      </BridgeOptionContainer>
    </ExternalLinkWarning>
  );
};

export const format3rdPartyLink = (link: string, state: AppState) => {
  return link
    .replace('{currency}', state.selectedCurrency.toUpperCase())
    .replace('?symbol={symbol}', `?amount=${state.inputValue}&symbol=${state.selectedCurrency}`);
};

export const BridgeInfoBanner = ({ logoSrc, children }: { logoSrc?: string; children: ReactElement }) => {
  return (
    <div className="border-yellow-500 bg-neutral-1000 border relative flex items-center p-4 rounded-lg text-sm text-[#ADAEB1]">
      {logoSrc ? (
        <div className="min-w-4 mr-4">
          <Image className="saturate-0" src={logoSrc} width={20} height={20} alt="info" />
        </div>
      ) : null}
      <div>{children}</div>
    </div>
  );
};
