import { BridgeSupportedRoutes, BridgeView } from '@/utils/thirdPartyBridges/types';

export const shouldShowThirdPartyBridge = ({
  supportedRoutes,
  view,
  selectedChainId,
  selecteTokenSymbol,
}: {
  supportedRoutes?: BridgeSupportedRoutes;
  view: BridgeView;
  selectedChainId: number;
  selecteTokenSymbol: string;
}) => {
  // always show 3rd party bridge if no supported routes are provided
  if (!supportedRoutes) return true;
  return supportedRoutes[view].some(
    (route) =>
      route.chainId === selectedChainId && route.token.toLocaleLowerCase() === selecteTokenSymbol.toLocaleLowerCase()
  );
};
