'use client';

import { useLocationContext } from '@/app/context/LocationContext';
import { useBridgeContext, Source } from '@/BridgeProvider';
import { Button, BridgeIcon, ConfirmDialog } from '@/components';
import { InlineLoader } from '@/components/InlineLoader';
import { ReviewWithdrawal } from '@/components/ReviewWithdrawal';
import localConfig from '@/config';
import { useBridgeRecipient } from '@/hooks/bridge/useBridgeRecipient';
import { useThirdPartyOnlyBridge } from '@/hooks/bridge/useThirdPartyOnlyBridge';
import { useTokenApprove } from '@/hooks/bridge/useTokenApprove';
import { useConnectModal } from '@rainbow-me/rainbowkit';
import Image from 'next/image';
import { useCallback, useEffect } from 'react';
import { toast } from 'react-toastify';
import { useToggle } from 'react-use';
import { useAccount } from 'wagmi';
import { BridgeView } from '@/utils/thirdPartyBridges/types';

// TODO: refactor
export const SubmitBridgeButton = ({
  onSubmitHandler,
  isLoading,
  source,
}: {
  onSubmitHandler: () => Promise<void | null | `0x${string}` | unknown>;
  isLoading: boolean;
  source: Source;
}) => {
  const [showSameAddressWarning, toggleShowSameAddressWarning] = useToggle(false);
  const { address, chain } = useAccount();
  const { isConnected } = useAccount();
  const { state } = useBridgeContext();
  const { openConnectModal } = useConnectModal();
  const isDeposit = state.view === BridgeView.DEPOSIT;
  const layer = isDeposit ? 'L1_balances' : 'L2_balances';
  const isBalanceExceeded = +state[layer][state.selectedCurrency] < +state.inputValue;
  const { isThirdPartyOnlyTokenSelected } = useThirdPartyOnlyBridge(source);
  const { allowance, approve, approving, waiting, setWaiting, approveValue } = useTokenApprove(state.from.token);
  const { isReadOnly } = useLocationContext();
  const { isRecipientRequired, isRecipientInputValid } = useBridgeRecipient();
  const approveSuccessMessage = isDeposit ? 'You can now Bridge to mode' : 'You can now Withdraw from mode';

  useEffect(() => {
    if (waiting && allowance !== undefined && allowance !== null && +allowance >= +state.inputValue) {
      setWaiting(false);

      if (approveValue) {
        toast.success(
          <div className="uppercase">
            <p>Approval confirmed successfully</p>
            <p>{approveSuccessMessage}</p>
          </div>
        );
      }
    }
  }, [allowance, setWaiting, state.inputValue, waiting, approveValue, approveSuccessMessage]);

  function getSubmitButtonLabel() {
    if (isLoading) {
      return (
        <>
          <Image
            src="/assets/icons/loading-icon-grey.svg"
            alt="Close"
            width={24}
            height={24}
            className="animate-spin"
          />
          <InlineLoader label="Processing..." className="text-neutral-950" />
        </>
      );
    }

    if (isDeposit) {
      return 'Bridge to MODE';
    }

    return 'Bridge to ETHEREUM';
  }

  const isNetworkMismatch =
    (isDeposit && chain?.id !== parseInt(localConfig.l1ChainId)) ||
    (!isDeposit && chain?.id !== parseInt(localConfig.l2ChainId));

  const isMissingRecipient = isRecipientRequired && !state.recipientValue;

  const handleSubmit = useCallback(async () => {
    if (isRecipientRequired && address?.toLowerCase() === state.recipientValue?.toLowerCase()) {
      toggleShowSameAddressWarning(true);
    } else {
      await onSubmitHandler();
    }
  }, [address, isRecipientRequired, onSubmitHandler, state.recipientValue, toggleShowSameAddressWarning]);

  if (isReadOnly || isThirdPartyOnlyTokenSelected) return null;

  if (!isConnected) {
    return (
      <Button onClick={openConnectModal} className="w-full flex items-center justify-center">
        Connect Wallet
      </Button>
    );
  }

  if (isNetworkMismatch) {
    return (
      <Button onClick={onSubmitHandler} className="w-full flex items-center justify-center">
        Switch network
      </Button>
    );
  }

  if (!isDeposit && isConnected) {
    return <ReviewWithdrawal onSubmit={onSubmitHandler} isLoading={isLoading} />;
  }

  if (allowance !== undefined && allowance !== null && +allowance < +state.inputValue) {
    const approveNotRejected = waiting && !!approveValue;
    return (
      <Button
        onClick={async () => {
          setWaiting(true);
          await approve();
        }}
        className="w-full flex items-center justify-center"
        disabled={approving || approveNotRejected}
      >
        {approving || approveNotRejected ? (
          <Image
            src="/assets/icons/loading-icon-grey.svg"
            alt="Close"
            width={24}
            height={24}
            className="animate-spin"
          />
        ) : null}
        {approving || approveNotRejected ? 'Approving Transaction' : 'Approve'}
      </Button>
    );
  }

  const isAllowedToBridge =
    state.selectedCurrency === 'ETH' || (allowance != null && Number(allowance) >= Number(state.inputValue));

  return (
    <>
      <Button
        onClick={handleSubmit}
        className="w-full flex items-center justify-center gap-x-2"
        disabled={
          !(Number(state.inputValue) > 0) ||
          isBalanceExceeded ||
          !isAllowedToBridge ||
          isMissingRecipient ||
          !isRecipientInputValid ||
          isLoading
        }
      >
        <BridgeIcon />
        {getSubmitButtonLabel()}
      </Button>
      <ConfirmDialog
        open={showSameAddressWarning}
        onClose={() => toggleShowSameAddressWarning(false)}
        onSubmit={() => {
          toggleShowSameAddressWarning(false);
          onSubmitHandler();
        }}
        title="Confirm"
        text="Ensure that sending to multisig that is as same as requester multisig address means that multisig must exist on Mode or vice versa"
      />
    </>
  );
};
