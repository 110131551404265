'use client';

import { Button, Spinner } from '@/components';
import { SeasonalBanner, useSeasonalBanners } from '@/hooks/content';
import clsx from 'clsx';
import Image from 'next/image';
import Link from 'next/link';
import { FC, MouseEventHandler, MouseEvent, useCallback, useMemo } from 'react';
import { useAccount } from 'wagmi';
import TOP_USERS_WALLETS from '@/constants/top_users_wallets.json';

const HEADER_OFFSET = 80;

interface InfoBlockProps {
  title: string;
  text?: React.ReactNode;
  icon?: string;
  link?: string;
  label?: string;
  labelColor?: string;
  children?: React.ReactNode;
  backgroundImage?: string;
  buttonText?: string;
  buttonLink?: string;
  extraButtonText?: React.ReactNode;
  extraButtonLink?: string;
  isAnchor?: boolean;
}

export const SeasonDetailsBanner: FC<
  InfoBlockProps & { className?: string; buttonVariant?: 'primary' | 'secondary' }
> = ({
  title,
  text,
  icon,
  backgroundImage: bannerBackgroundImage,
  buttonText,
  buttonLink,
  extraButtonText,
  extraButtonLink,
  label,
  labelColor,
  isAnchor,
  buttonVariant,
  className,
}) => {
  const onLinkClickHandler = useCallback<MouseEventHandler<HTMLAnchorElement>>(
    (event: MouseEvent) => {
      if (isAnchor && buttonLink) {
        const targetNode = document.getElementById(buttonLink);
        if (targetNode) {
          event.preventDefault();
          const offsetPosition = targetNode.getBoundingClientRect().top + window.scrollY - HEADER_OFFSET;

          window.scrollTo({
            top: offsetPosition,
            behavior: 'smooth',
          });
        }
      }
    },
    [isAnchor, buttonLink]
  );
  /* Its a decentralized exchange for swapping BEP tokens on Binance Smart Chain */

  return (
    <div
      className={clsx(
        'flex flex-col justify-between gap-3 py-3.5 pt-0 flex-1 border border-neutral-900 rounded-2xl overflow-hidden min-h-[286px]',
        className
      )}
    >
      <div className="flex flex-col gap-3">
        <div className="flex flex-row gap-2 text-neutral-100 text-lg items-center justify-between">
          <div className="flex flex-col items-center gap-2 w-full">
            <div
              style={{ ['--image-url' as string]: `url(${bannerBackgroundImage})` }}
              className="flex gap-x-2 items-center justify-center pb-5 mb-5 w-full pt-4 bg-center bg-cover bg-[image:var(--image-url)] bg-no-repeat"
            >
              {icon && <Image src={icon} width={32} height={32} alt="Icn" className="h-auto" />}
              {label && (
                <div
                  className={clsx(
                    'justify-center border text-sm flex rounded px-2 font-normal font-mono',
                    labelColor ? `border-[${labelColor}] text-[${labelColor}]` : 'border-[#00B2EE] text-[#00B2EE]'
                  )}
                >
                  {label}
                </div>
              )}
            </div>
            <div className="text-white tracking-wide text-lg px-8">
              {title && <span className="uppercase">{title}</span>}
            </div>
          </div>
        </div>
        {text && <div className={`font-sans text-[#ADAEB1] text-sm font-normal px-8 text-center`}>{text}</div>}
      </div>
      {(buttonText || extraButtonText) && (
        <div className="text-neutral-500 text-xs font-normal font-mono flex mt-1 gap-x-2 justify-center px-6">
          {buttonText && (
            <BannerButton
              link={buttonLink || ''}
              text={buttonText}
              onClick={onLinkClickHandler}
              buttonVariant={buttonVariant}
            />
          )}
          {extraButtonText && (
            <BannerButton
              link={extraButtonLink || ''}
              text={extraButtonText}
              onClick={onLinkClickHandler}
              buttonVariant={buttonVariant}
            />
          )}
        </div>
      )}
    </div>
  );
};

const BannerButton = ({
  text,
  link,
  onClick,
  buttonVariant = 'secondary',
}: {
  text: React.ReactNode;
  link: string;
  onClick: MouseEventHandler<HTMLAnchorElement>;
  buttonVariant?: 'primary' | 'secondary';
}) => {
  return (
    <Link href={link || ''} target="_blank" onClick={onClick} className="inline-block w-full">
      <Button
        filled={false}
        className={clsx(
          buttonVariant === 'primary' ? 'bg-yellow-500 text-black' : 'bg-[#DFE0E10A] border-neutral-900 text-[#DFE0E1]',
          'font-medium h-auto rounded-xl py-[8px] px-4 flex justify-center items-center text-base normal-case w-full font-sans'
        )}
      >
        {text}
      </Button>
    </Link>
  );
};

export const SeasonDetailsBanners = () => {
  const { address } = useAccount();
  const { data: seasonalBanners, isLoading: isSeasonalBannersLoading } = useSeasonalBanners();

  const isUserInTopList = useMemo(
    () => address && TOP_USERS_WALLETS.some((item) => item.toLowerCase() === address.toLowerCase()),
    [address]
  );

  const processedBanners = useMemo(() => {
    if (!seasonalBanners) return [];
    if (!isUserInTopList) return seasonalBanners.filter((banner) => !banner.isSelectedUsersGroup);
    const idsToRemove = new Set();

    for (const item of seasonalBanners) {
      if (item.isSelectedUsersGroup && item.bannerIdToReplace != null) {
        idsToRemove.add(item.bannerIdToReplace);
      }
    }

    return seasonalBanners.filter((item) => !idsToRemove.has(item.id));
  }, [seasonalBanners, address, isUserInTopList]);

  if (isSeasonalBannersLoading) return <Spinner />;

  if (!processedBanners.length) return null;

  return (
    <div
      className={`grid grid-cols-[1fr] md:grid-cols-[1fr_1fr] lg:grid-cols-[1fr_1fr_1fr] w-full justify-between relative my-6 text-yellow-500 font-medium font-chakra gap-4`}
    >
      {processedBanners.map(
        ({
          id,
          title,
          text,
          icon,
          backgroundImage,
          buttonText,
          buttonLink,
          label,
          labelColor,
          isAnchor,
          extraButtonLink,
          extraButtonText,
        }) => {
          return (
            <SeasonDetailsBanner
              key={id}
              title={title!}
              text={text}
              icon={icon}
              backgroundImage={backgroundImage}
              buttonLink={buttonLink}
              extraButtonLink={extraButtonLink}
              buttonText={buttonText}
              extraButtonText={extraButtonText}
              label={label}
              labelColor={labelColor}
              isAnchor={isAnchor}
            />
          );
        }
      )}
    </div>
  );
};
