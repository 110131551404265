import { Token } from '@/types/token';
import localConfig from '@/config';

export const NATIVE_TOKEN_ADDRESS = '0x0000000000000000000000000000000000000000';

export const NETWORK_TOKENS: Record<number, Token[]> = localConfig.whitelistedTokens ?? {
  919: [
    {
      contractAddress: NATIVE_TOKEN_ADDRESS,
      symbol: 'ETH',
      decimals: 18,
      icon: '/assets/icons/eth_rounded.svg',
    },
  ],
  11155111: [
    {
      contractAddress: NATIVE_TOKEN_ADDRESS,
      symbol: 'ETH',
      decimals: 18,
      icon: '/assets/icons/eth_rounded.svg',
    },
  ],
  34443: [
    {
      contractAddress: NATIVE_TOKEN_ADDRESS,
      symbol: 'ETH',
      decimals: 18,
      icon: '/assets/icons/eth_rounded.svg',
    },
    {
      contractAddress: '0xf0F161fDA2712DB8b566946122a5af183995e2eD',
      symbol: 'USDT',
      decimals: 6,
      icon: '/cryptoIcons/usdt.svg',
    },
    {
      contractAddress: '0xd988097fb8612cc24eeC14542bC03424c656005f',
      symbol: 'USDC',
      decimals: 6,
      icon: '/cryptoIcons/usdc.svg',
    },
    {
      contractAddress: '0xcDd475325D6F564d27247D1DddBb0DAc6fA0a5CF',
      symbol: 'WBTC',
      decimals: 8,
      icon: '/cryptoIcons/wbtc.svg',
    },
  ],
  1: [
    {
      contractAddress: NATIVE_TOKEN_ADDRESS,
      symbol: 'ETH',
      decimals: 18,
      icon: '/assets/icons/eth_rounded.svg',
    },
    {
      contractAddress: '0xdac17f958d2ee523a2206206994597c13d831ec7',
      symbol: 'USDT',
      decimals: 6,
      icon: '/cryptoIcons/usdt.svg',
    },
    {
      contractAddress: '0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48',
      symbol: 'USDC',
      decimals: 6,
      icon: '/cryptoIcons/usdc.svg',
    },
    {
      contractAddress: '0x2260fac5e5542a773aa44fbcfedf7c193bc2c599',
      symbol: 'WBTC',
      decimals: 8,
      icon: '/cryptoIcons/wbtc.svg',
    },
  ],
};

export const THIRD_PARTY_BRIDGE_ONLY_TOKENS = [
  'ezETH',
  'mBTC',
  'weETH.mode',
  'BBTC',
  'BBUSD',
  'sDAI',
  'aBTC',
  'USDe',
  'vLiSTX',
  'ALEX',
  'uBTC',
  'uniBTC',
];
export const THIRD_PARTY_BRIDGE_ONLY_NETWORK_IDS = [10, 8453, 42161, 137];

export const OP_TOKEN_ADDRESS = '0x4200000000000000000000000000000000000042';

export const MERKL_DISTRIBUTOR_ADDRESS_OP = '0x3Ef3D8bA38EBe18DB133cEc108f4D14CE00Dd9Ae';
