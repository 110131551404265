'use client';

import { ReactNode, useState } from 'react';
import { ChevronIcon } from '@/components/icons';
import { twMerge } from 'tailwind-merge';

export const ExpandableSection = ({
  children,
  title,
  className,
  isExpandedByDefault,
}: {
  children: ReactNode;
  title: string;
  className?: string;
  isExpandedByDefault?: boolean;
}) => {
  const [isExpanded, setIsExpanded] = useState<boolean>(!!isExpandedByDefault);

  return (
    <div className={twMerge('bg-grey-900 p-4 rounded-lg text-neutral-100 font-sans', className)}>
      <div className="flex items-center justify-between cursor-pointer" onClick={() => setIsExpanded(!isExpanded)}>
        <h3 className="font-semibold text-base uppercase">{title}</h3>
        <ChevronIcon size={40} className={`text-white transform ${isExpanded ? 'rotate-180' : ''}`} />
      </div>
      {isExpanded && children}
    </div>
  );
};
