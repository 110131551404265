import { baseLocale, useLocalizationContext } from '@/app/context';
import localConfig from '@/config';
import { QueryKeys } from '@/constants/queryKeys';
import { contentfulClient } from '@/utils/contentful';
import { useQuery } from '@tanstack/react-query';
import axios, { AxiosResponse } from 'axios';
import { LocalizedStringEntry, LocalizedStringSkeleton } from '../types';

const fetchUILocalization = async () => {
  try {
    let content = [];

    if (localConfig.showContentLive) {
      content = (
        await contentfulClient.withAllLocales.getEntries<LocalizedStringSkeleton>({
          content_type: 'localizedString',
          limit: 1000,
        })
      ).items;
    } else {
      content = (
        await axios.get<void, AxiosResponse<LocalizedStringEntry[]>>(
          `${localConfig.contentfulUrl}/localizedStrings.json`
        )
      ).data;
    }

    return content.map(({ fields }) => ({
      localizedValue: fields.localizedValue,
      name: fields.name[baseLocale],
    }));
  } catch (e) {
    console.error(e);
    return;
  }
};

export const useLocalizedContentfulStrings = (options = {}) => {
  const { locale } = useLocalizationContext();

  const content = useQuery({
    queryKey: [QueryKeys.UI_LOCALIZATION],
    queryFn: fetchUILocalization,
    refetchOnWindowFocus: localConfig.showContentLive,
    ...options,
  });

  return {
    localize: (name: string) => {
      const item = content.data?.find((item) => item.name === name);
      return item?.localizedValue[locale] || '';
    },
  };
};

export type LocalizedString = NonNullable<Awaited<ReturnType<typeof fetchUILocalization>>>[number];
