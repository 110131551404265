'use client';

import { useBridgeContext, Source } from '@/BridgeProvider';
import { Divider, SectionContainer } from '@/components';
import { GlobalAddressSection, BridgeOptions, TokenSelector, Tabs, SupportSection } from '@/components/Bridge';
import { useInitialBridgeParams } from '@/hooks/bridge/useInitialBridgeParams';

export const BridgePage = () => {
  const source = Source.FROM;
  const { state } = useBridgeContext();
  useInitialBridgeParams();

  return (
    <SectionContainer className="w-full md:p-8 p-4 gap-y-8">
      <Tabs />
      <TokenSelector source={source} />
      <Divider type="horizontal" />
      <BridgeOptions view={state.view} />
      <Divider type="horizontal" />
      <GlobalAddressSection />
      <Divider type="horizontal" />
      <SupportSection />
    </SectionContainer>
  );
};
