export const CopyIcon = ({ className, size = 20 }: { className?: string; size?: number }) => {
  return (
    <svg
      className={className}
      width={size}
      height={size}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.83333 2.5H17.5V14.175H14.1667V17.5H2.5V5.83333H5.83333V2.5ZM3.75 7.08333V16.25H12.9167V7.08333H3.75ZM14.1667 12.925V5.83333H7.08333V3.75H16.25V12.925H14.1667Z"
        fill="currentColor"
      />
    </svg>
  );
};
