'use client';

import clsx from 'clsx';
import { useBridgeContext } from '@/BridgeProvider';
import { BridgeView } from '@/utils/thirdPartyBridges/types';
import { useLocalizedContentfulStrings } from '@/hooks/content';

export const Tabs = () => {
  const { state, dispatch } = useBridgeContext();
  const { localize } = useLocalizedContentfulStrings();

  function renderTab(label: string, view: typeof state.view) {
    return (
      <div
        className={clsx(
          state.view === view
            ? 'bg-yellow-500/[.08] rounded text-yellow-500 border border-yellow-500'
            : 'cursor-pointer text-neutral-100',
          'font-medium w-full text-xs uppercase flex justify-center items-center p-2'
        )}
        onClick={() => state.view !== view && dispatch({ type: 'SET_VIEW', payload: view })}
      >
        {label}
      </div>
    );
  }

  return (
    <div className="flex border rounded-lg p-1 border-neutral-900">
      {renderTab(localize('deposit'), BridgeView.DEPOSIT)}
      {renderTab(localize('withdraw'), BridgeView.WITHDRAW)}
    </div>
  );
};
