'use client';

import { EstimatedFee, Address } from '@/hooks/bridge/useGlobalAddress';
import { useLocalizedContentfulStrings } from '@/hooks/content';
import { ExpandableSection, GasIcon, Spinner } from '@/components';
import Link from 'next/link';
import Image from 'next/image';

const ExtimatedFeeItem = ({ estimate }: { estimate: EstimatedFee }) => {
  return (
    <div className="flex items-center gap-x-2 bg-grey-450 rounded-lg font-sans px-3 py-2 shrink-0">
      <div className="relative">
        <Image className="rounded-full" src={estimate.tokenIcon} width={24} height={24} alt={estimate.name} />
        {estimate.networkIcon && (
          <Image
            className="rounded-full absolute -right-[2px] -top-[2px]"
            src={estimate.networkIcon}
            width={12}
            height={12}
            alt={`${estimate.name}-${estimate.chainId}`}
          />
        )}
      </div>
      <div>
        <p className="font-semibold flex">{estimate.name}</p>
        <p className="flex gap-x-1 font-medium text-[10px]">
          <GasIcon size={12} />
          {`~${estimate.fee} ${estimate.name}`}
        </p>
      </div>
    </div>
  );
};

export const SupportedTokensAndFeesSection = ({
  address,
  estimates,
  estimatesIsLoading,
}: {
  address?: Address;
  estimates?: EstimatedFee[];
  estimatesIsLoading: boolean;
}) => {
  const { localize } = useLocalizedContentfulStrings();

  if (!estimates && !estimatesIsLoading) return null;

  return (
    <ExpandableSection isExpandedByDefault title={localize('supportedTokensAndFeesSectionTitle')}>
      <div className="flex flex-col items-center gap-y-4">
        <p className="text-sm">
          {localize('supportedTokensAndFeesSectionDesc')}{' '}
          <Link
            className="underline"
            href={`https://global-address.zerodev.app/address/${address}`}
            target="_blank"
            title="Global address details"
          >
            {localize('moreInfo')}
          </Link>
        </p>
        <div className="flex flex-wrap gap-4">
          {estimatesIsLoading ? (
            <Spinner />
          ) : (
            estimates?.map((estimate) => (
              <ExtimatedFeeItem key={`${estimate.name}-${estimate.chainId}`} estimate={estimate} />
            ))
          )}
        </div>
      </div>
    </ExpandableSection>
  );
};
