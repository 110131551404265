export const WalletIcon = ({ className, size = 12 }: { className?: string; size?: number }) => {
  return (
    <svg
      className={className}
      width={size}
      height={size}
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.975098 3C0.975098 2.44772 1.42281 2 1.9751 2H10.0001V3.25H10.9751V10H2.3501C1.59071 10 0.975098 9.38439 0.975098 8.625L0.975098 3ZM1.7251 3.9685V8.625C1.7251 8.97018 2.00492 9.25 2.3501 9.25H10.2251V4H1.9751C1.88877 4 1.805 3.98906 1.7251 3.9685ZM1.7251 3C1.7251 3.13807 1.83703 3.25 1.9751 3.25H9.25012V2.75H1.9751C1.83703 2.75 1.7251 2.86193 1.7251 3ZM7.50012 6.625C7.50012 6.21079 7.83591 5.875 8.25012 5.875C8.66434 5.875 9.00012 6.21079 9.00012 6.625C9.00012 7.03921 8.66434 7.375 8.25012 7.375C7.83591 7.375 7.50012 7.03921 7.50012 6.625Z"
        fill="currentColor"
      />
    </svg>
  );
};
