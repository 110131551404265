import {
  createGlobalAddress,
  CreateGlobalAddressParams,
  getGlobalAddressFeeEstimates,
  getGlobalAddressStatus,
  createCall,
  FLEX,
} from '@zerodev/global-address';
import { useAccount } from 'wagmi';
import { mode, base } from 'viem/chains';
import { useState, useEffect } from 'react';
import { erc20Abi, Address as AddressType } from 'viem';
import { writeToLocalStorage, getFromLocalStorage } from '@/utils/localStorage';
import { formatHexStringToScaledNumber, getSupportedTokenIcon, getSupportedNetworkIconByChainId } from '@/utils';

export type EstimatedFee = { name: string; fee: number; networkIcon: string; chainId: number; tokenIcon: string };
export type Address = AddressType;
export type Transaction = { tokenAddress: Address; inputAmount: string; outputAmount: string };
export type Transactions = Record<AddressType, Transaction>;

const supportedChains = [base];

const srcTokens: CreateGlobalAddressParams['srcTokens'] = supportedChains.flatMap((chain) => {
  return [
    {
      tokenType: 'ERC20',
      chain,
    },
    {
      tokenType: 'NATIVE',
      chain,
    },
  ];
});

export const useGlobalAddress = () => {
  const [address, setAddress] = useState<Address>();
  const [estimates, setEstimates] = useState<EstimatedFee[]>();
  const [transactions, setTransactions] = useState<Transactions>();
  const [globalAddressIsLoading, setGlobalAddressIsLoading] = useState(false);
  const [estimatesIsLoading, setEstimatesIsLoading] = useState(false);
  const { address: owner } = useAccount();
  const globalAddressFromLocalStorage = getFromLocalStorage<Address>(`globalAddress-${owner}`);
  const [error, setError] = useState<string>();

  const saveGlobalAddress = (globalAddress: Address) => {
    setAddress(globalAddress);
    writeToLocalStorage(`globalAddress-${owner}`, globalAddress);
  };

  const saveEstimates = async (globalAddress: Address) => {
    try {
      setEstimatesIsLoading(true);
      const { estimatedFees } = await getGlobalAddressFeeEstimates({ globalAddress });

      setEstimates(
        estimatedFees.reduce((acc, cur) => {
          cur.data.forEach(({ name, fee, decimal }) => {
            const scaledFee = formatHexStringToScaledNumber(fee, decimal);
            if (!acc.find((item) => name === item.name && cur.chainId === item.chainId)) {
              acc.push({
                name,
                fee: scaledFee,
                networkIcon: getSupportedNetworkIconByChainId(cur.chainId),
                chainId: cur.chainId,
                tokenIcon: getSupportedTokenIcon(name),
              });
            }
          });
          return acc;
        }, [] as EstimatedFee[])
      );
    } catch (error) {
      console.error(error);
    } finally {
      setEstimatesIsLoading(false);
    }
  };

  const saveTransactionHashes = async (globalAddress: Address) => {
    try {
      const status = await getGlobalAddressStatus({ globalAddress });

      setTransactions(
        status.deposits.reduce((acc, cur) => {
          if (cur.execution && !Object.keys(acc).includes(cur.execution.transactionHash)) {
            acc[cur.execution.transactionHash] = {
              tokenAddress: cur.execution.outputToken,
              inputAmount: cur.deposit.amount,
              outputAmount: cur.execution.outputAmount,
            };
          }
          return acc;
        }, {} as Transactions)
      );
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    const loadData = async () => {
      if (globalAddressFromLocalStorage) {
        if (!estimates) await saveEstimates(globalAddressFromLocalStorage);
        if (!address) saveGlobalAddress(globalAddressFromLocalStorage);
        if (!transactions) await saveTransactionHashes(globalAddressFromLocalStorage);
      }
    };
    void loadData();
  }, [globalAddressFromLocalStorage, estimates, address, transactions]);

  const generateGlobalAddress = async ({ onSuccess }: { onSuccess?: () => void }) => {
    if (!owner) {
      throw new Error('generateGlobalAddress: @zerodev/global-address is not configured.');
    }

    try {
      setGlobalAddressIsLoading(true);
      const { globalAddress }: { globalAddress: Address } = await createGlobalAddress({
        owner,
        destChain: mode,
        srcTokens,
        actions: {
          ERC20: {
            action: [
              createCall({
                target: FLEX.TOKEN_ADDRESS,
                value: BigInt(0),
                abi: erc20Abi,
                functionName: 'transfer',
                args: [owner, FLEX.AMOUNT],
              }),
            ],
            fallBack: [],
          },
          NATIVE: {
            action: [
              createCall({
                target: owner,
                value: FLEX.NATIVE_AMOUNT,
              }),
            ],
            fallBack: [],
          },
        },
      });

      await Promise.all([
        saveGlobalAddress(globalAddress),
        saveEstimates(globalAddress),
        saveTransactionHashes(globalAddress),
      ]);

      if (onSuccess) onSuccess();
    } catch (error) {
      console.error(error);
      setError('Failed to generate global address.');
    } finally {
      setGlobalAddressIsLoading(false);
    }
  };

  return {
    generateGlobalAddress,
    address,
    estimates,
    transactions,
    globalAddressIsLoading,
    estimatesIsLoading,
    error,
  };
};
