'use client';

import { SectionTitle } from './SectionTitle';
import { TradingIcon, Tooltip, InfoIcon } from '@/components';
import { useLocalizedContentfulStrings } from '@/hooks/content';
import Image from 'next/image';
import { GlobalAddressGenerationInput } from '@/components/Bridge/GlobalAddressGenerationInput';
import Link from 'next/link';
import { usePathname } from 'next/navigation';

const CryptoExchanges: Array<{ name: string; logo: string }> = [
  {
    name: 'Binance',
    logo: '/assets/icons/binance.svg',
  },
  {
    name: 'Coinbase',
    logo: '/assets/icons/coinbase.svg',
  },
  {
    name: 'Kraken',
    logo: '/assets/icons/kraken.svg',
  },
];

export const GlobalAddressSection = () => {
  const { localize } = useLocalizedContentfulStrings();
  const isGlobalAddressPage = usePathname().includes('/bridge/global-address');

  return (
    <div>
      <div className="flex items-center justify-between mb-2">
        <SectionTitle>
          <TradingIcon />
          {isGlobalAddressPage
            ? `${localize('sendFunds')} ${localize('globalAddressTitle')}`
            : localize('globalAddressTitle')}
          <Tooltip
            align="center"
            trigger={
              <div>
                <InfoIcon className="text-neutral-100" />
              </div>
            }
          >
            {localize('globalAddressTooltipContent')}{' '}
            <Link
              className="underline"
              href="https://global-address.zerodev.app/"
              target="_blank"
              title="ZeroDev documentation"
            >
              {localize('moreInfo')}
            </Link>
          </Tooltip>
        </SectionTitle>
        <div className="gap-x-2 hidden sm:flex">
          {CryptoExchanges.map(({ name, logo }) => (
            <Image key={name} src={logo} alt={name} width={24} height={24} />
          ))}
        </div>
      </div>
      <p className="font-sans text-sm text-neutral-100 mb-8">{localize('globalAddressDesc')}</p>
      <GlobalAddressGenerationInput isGlobalAddressPage={isGlobalAddressPage} />
    </div>
  );
};
