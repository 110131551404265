export const HistoryIcon = ({ className, size = 24 }: { className?: string; size?: number }) => {
  return (
    <svg
      className={className}
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.75 7V11.6893L16.5607 15.5L15.5 16.5607L11.25 12.3107V7H12.75Z"
        fill="currentColor"
      />
      <path fillRule="evenodd" clipRule="evenodd" d="M3.5 4V8H7.5V9.5H2V4H3.5Z" fill="currentColor" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.9867 3.5C8.45962 3.5 5.43248 5.64143 4.14042 8.69504L3.84817 9.38575L2.46674 8.80123L2.759 8.11052C4.27794 4.52069 7.83703 2 11.9867 2C17.516 2 22.0002 6.47612 22.0002 12C22.0002 17.5239 17.516 22 11.9867 22C7.62624 22 3.9178 19.2169 2.54323 15.3336L2.29297 14.6266L3.707 14.1261L3.95726 14.8331C5.12606 18.1351 8.28031 20.5 11.9867 20.5C16.6896 20.5 20.5002 16.6934 20.5002 12C20.5002 7.30661 16.6896 3.5 11.9867 3.5Z"
        fill="currentColor"
      />
    </svg>
  );
};
