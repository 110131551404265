import { useEffect } from 'react';
import { useSearchParams } from 'next/navigation';
import { useBridgeContext, Source } from '@/BridgeProvider';
import { L1_NETWORKS } from '@/constants/networks';
import { NETWORK_TOKENS } from '@/constants/tokens';
import localConfig from '@/config';

export const useInitialBridgeParams = () => {
  const searchParams = useSearchParams();
  const tokenSymbol = searchParams.get('token');
  const networkId = searchParams.get('network');
  const { dispatch } = useBridgeContext();

  useEffect(() => {
    const l1ChainId = +localConfig.l1ChainId;
    const chainIdFromParams = networkId
      ? L1_NETWORKS.find((network) => network.chainId === +networkId)?.chainId
      : l1ChainId;
    const payloadChainId = chainIdFromParams ?? l1ChainId;
    const tokenFromParams = NETWORK_TOKENS[l1ChainId]?.find(
      (token) => token.symbol.toLowerCase() === tokenSymbol?.toLowerCase()
    );
    const etherToken = NETWORK_TOKENS[l1ChainId]?.find((token) => token.symbol === 'ETH');
    const payloadToken = tokenFromParams ?? etherToken;

    dispatch({ type: 'SET_SELECTED_NETWORK', payload: payloadChainId, source: Source.FROM });
    if (payloadToken) {
      dispatch({ type: 'SET_SELECTED_TOKEN', payload: payloadToken, source: Source.FROM });
    }
  }, [networkId, tokenSymbol]); // do not add deps here, it will cause infinite re-renders
};
