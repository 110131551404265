'use client';

import { useTransactionDetails } from '@/hooks/useTransactionDetails';
import { Address, Transactions, Transaction } from '@/hooks/bridge/useGlobalAddress';
import { SectionContainer, HistoryIcon, Divider, ExternalLinkIcon, Spinner } from '@/components';
import { SectionTitle } from './SectionTitle';
import { useLocalizedContentfulStrings } from '@/hooks/content';
import { useMemo } from 'react';
import { scaleNumber, readContractFunctionMode } from '@/utils';
import Image from 'next/image';
import { format } from 'date-fns';
import Link from 'next/link';
import localConfig from '@/config';
import { useAsync } from 'react-use';
import { getSupportedTokenIcon } from '@/utils';

const TransactionRow = ({ transaction, transactionHash }: { transaction: Transaction; transactionHash: string }) => {
  const { tokenAddress, inputAmount, outputAmount } = transaction;
  const { data: transactionDetails, isLoading: isTransactionDetailsLoading } = useTransactionDetails(transactionHash);
  const { value: tokenDetails, loading: isTokenDetailsLoading } = useAsync(async () => {
    const decimals = await readContractFunctionMode(tokenAddress, 'decimals');
    const symbol = await readContractFunctionMode(tokenAddress, 'symbol');

    return { decimals, symbol };
  }, [tokenAddress]);

  const formattedInputAmount = useMemo(
    () => (tokenDetails?.decimals ? scaleNumber(parseInt(inputAmount), tokenDetails.decimals) : ''),
    [inputAmount, tokenDetails]
  );
  const formattedOutputAmount = useMemo(
    () => (tokenDetails?.decimals ? scaleNumber(parseInt(outputAmount), tokenDetails.decimals) : ''),
    [outputAmount, tokenDetails]
  );

  if (isTokenDetailsLoading) return <Spinner className="pt-4" size={24} />;

  return (
    <div className="flex flex-col sm:flex-row justify-between text-neutral-100 gap-y-2 pt-4">
      <div className="w-full flex items-center gap-x-3">
        {tokenDetails?.symbol && (
          <>
            <Image
              className="rounded-full"
              src={getSupportedTokenIcon(tokenDetails.symbol)}
              width={24}
              height={24}
              alt={tokenDetails.symbol}
              priority
            />
            <p className="font-semibold">{tokenDetails.symbol}</p>
          </>
        )}
      </div>
      {formattedInputAmount && formattedOutputAmount && (
        <div className="w-full text-sm">{`${formattedInputAmount} > ${formattedOutputAmount}`}</div>
      )}
      <div className="w-full flex items-center gap-x-2">
        {isTransactionDetailsLoading ? (
          <Spinner size={24} />
        ) : (
          <>
            {transactionDetails ? format(transactionDetails.timestamp, 'HH:mm MMM dd') : ''}
            <Link target="_blank" href={`${localConfig.explorerUrl}/tx/${transactionHash}`}>
              <ExternalLinkIcon size={16} />
            </Link>
          </>
        )}
      </div>
    </div>
  );
};

const TransactionsTableHeader = ({ title }: { title: string }) => {
  return <p className="w-full text-sm text-neutral-600 uppercase font-bold">{title}</p>;
};

export const DepositHistory = ({ transactions }: { transactions?: Transactions }) => {
  const transactionHashes = transactions ? Object.keys(transactions) : [];
  const { localize } = useLocalizedContentfulStrings();

  return (
    <SectionContainer className="flex flex-col gap-y-8 p-8">
      <SectionTitle>
        <HistoryIcon />
        {localize('depositHistory')}
      </SectionTitle>
      {transactions &&
        (transactionHashes.length ? (
          <div>
            <div className="flex">
              <TransactionsTableHeader title={localize('asset')} />
              <TransactionsTableHeader title={localize('amount')} />
              <TransactionsTableHeader title={localize('completed')} />
            </div>
            {transactionHashes.map((transactionHash, index) => (
              <div key={transactionHash}>
                <TransactionRow
                  transactionHash={transactionHash}
                  transaction={transactions[transactionHash as Address]}
                />
                {index !== transactionHashes.length - 1 && <Divider className="my-0 mt-4" />}
              </div>
            ))}
          </div>
        ) : (
          <p className="text-neutral-600 text-center text-sm font-sans">{localize('noDeposiyHistory')}</p>
        ))}
    </SectionContainer>
  );
};
