import type { SVGProps } from 'react';
import { Ref, forwardRef } from 'react';
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgCalendarIcon = (
  { title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-hidden="true"
    ref={ref}
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.66667 1.3335V2.66683H10.3333V1.3335H11.3333V2.66683H14V14.0002H2V2.66683H4.66667V1.3335H5.66667ZM3 3.66683V6.00016H13V3.66683H3ZM13 7.00016H3V13.0002H13V7.00016Z"
      fill="currentColor"
    />
  </svg>
);
const ForwardRef = forwardRef(SvgCalendarIcon);
export default ForwardRef;
