'use client';
import { useBridgeContext } from '@/BridgeProvider';
import { KeyboardEvent } from 'react';
import fromExponential from 'from-exponential';

const trimDecimalsOverflow = (n: string, decimals: number) => {
  n += '';

  const arr = n.split('.');
  const integerPart = arr[0];
  const decimalPart = arr[1];
  const decimalPartConverted: string = decimalPart ? decimalPart.substr(0, decimals) : '';

  return decimalPartConverted ? `${integerPart}.${decimalPartConverted}` : integerPart;
};

export const BridgeAmountInput = () => {
  const { dispatch, state } = useBridgeContext();
  const allowedDecimals = state['from'].token?.decimals;

  const handleKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
    // Prevent 'e', '+', '-' from being entered
    if (event.key === 'e' || event.key === '+' || event.key === '-') {
      event.preventDefault();
    }
  };

  return (
    <input
      className="w-full overflow-hidden truncate max-h-10 bg-transparent text-[32px] font-sans leading-none outline-none text-neutral-100"
      type="number"
      min={0}
      placeholder="0.00"
      value={!state.inputValue && state.inputValue !== 0 ? '' : state.inputValue}
      onKeyDown={handleKeyDown}
      onChange={(e) => {
        const val = e.target.valueAsNumber;

        dispatch({
          type: 'SET_VALUE',
          payload: val ? fromExponential(Number(trimDecimalsOverflow(val.toString(), allowedDecimals))) : val,
        });
      }}
    />
  );
};
