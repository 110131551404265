'use client';

import { useState } from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import { twMerge } from 'tailwind-merge';
import { CheckIcon, CopyIcon } from '@/components';

export const CopyButton = ({
  copyValue,
  className,
  size = 'md',
  onClick,
}: {
  copyValue: string;
  className?: string;
  size?: 'sm' | 'md';
  onClick?: () => void;
}) => {
  const [isCopyFrom, setIsCopyFrom] = useState(false);

  if (isCopyFrom) {
    setTimeout(() => {
      setIsCopyFrom(false);
    }, 2500);
  }

  return (
    <CopyToClipboard
      text={copyValue}
      onCopy={() => {
        setIsCopyFrom(true);
        if (onClick) onClick();
      }}
    >
      <span
        className={twMerge(
          'text-neutral-600 hover:text-neutral-400 rounded flex items-center justify-center cursor-pointer',
          size === 'md' ? 'w-6 h-6' : 'w-4 h-4',
          className
        )}
      >
        {isCopyFrom ? <CheckIcon width={20} height={16} /> : <CopyIcon size={size === 'md' ? 24 : 16} />}
      </span>
    </CopyToClipboard>
  );
};
