'use client';

import { useGuide } from '@/app/context/GuideProvider';
import { useBridgeContext } from '@/BridgeProvider';
import { ReviewWithdrawalContent } from '@/components/ReviewWithdrawal/ReviewWithdrawalContent';
import { QueryKeys } from '@/constants/queryKeys';
import * as Dialog from '@radix-ui/react-dialog';
import { useQueryClient } from '@tanstack/react-query';
import Image from 'next/image';
import { useRouter } from 'next/navigation';
import { FC } from 'react';
import { useToggle } from 'react-use';
import { Button, BridgeIcon, CloseIcon } from '@/components';
import { useLocalizedContentfulStrings } from '@/hooks/content';

interface Props {
  onSubmit: () => Promise<void | null | `0x${string}` | unknown>;
  isLoading: boolean;
}

export const ReviewWithdrawal: FC<Props> = ({ onSubmit, isLoading }) => {
  const { state } = useBridgeContext();
  const [open, toggleOpen] = useToggle(false);
  const router = useRouter();
  const queryClient = useQueryClient();
  const { showGuideById } = useGuide();
  const { localize } = useLocalizedContentfulStrings();

  return (
    <>
      <Dialog.Root open={open}>
        <Dialog.Trigger asChild>
          <Button
            disabled={!state.inputValue}
            onClick={toggleOpen}
            className="w-full flex items-center justify-center gap-x-2"
          >
            <BridgeIcon />
            {`${localize('review')} ${localize('withdrawal')}`}
          </Button>
        </Dialog.Trigger>
        <Dialog.Portal>
          <Dialog.Overlay className="backdrop-blur-sm fixed inset-0 bg-neutral-black bg-opacity-90 z-50 grid items-center justify-center overflow-y-auto p-24 animate__animated animate__fadeIn animate__faster">
            <Dialog.Content className="text-white rounded-2xl overflow-hidden z-50 w-screen relative sm:w-fit sm:w-[680px] border border-grey-900">
              <div className="flex items-center justify-between bg-neutral-1000 w-full px-8 py-4">
                <h3 className="font-chakra text-lg uppercase font-bold">{`${localize('review')} ${localize('transaction')}`}</h3>
                <Dialog.Close onClick={toggleOpen}>
                  <CloseIcon className="text-grey-400" />
                </Dialog.Close>
              </div>

              <ReviewWithdrawalContent
                onSubmit={async () => {
                  const res = await onSubmit();

                  if (res instanceof Error && res.name === 'TransactionExecutionError') {
                    return;
                  }

                  if (res && 'rpcUrls' in (res as object)) {
                    // this is a switch network res
                    return;
                  }

                  setTimeout(async () => {
                    queryClient.removeQueries();
                    await queryClient.invalidateQueries({ queryKey: [QueryKeys.BRIDGE_MESSAGE] });

                    router.push('/transactions');
                    showGuideById('PendingWithdrawals');
                  }, 5000);
                }}
                isLoading={isLoading}
              />
            </Dialog.Content>
          </Dialog.Overlay>
        </Dialog.Portal>
      </Dialog.Root>
    </>
  );
};
