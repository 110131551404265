export type AcrossSupportedRoute = {
  destinationChainId: number;
  destinationToken: string;
  destinationTokenSymbol: string;
  isNative: boolean;
  originChainId: number;
  originToken: string;
  originTokenSymbol: string;
};

export type StargateSupportedChain = {
  chainKey: string;
  chainType: string;
  name: string;
  nativeChainId: number;
  nativeCurrency: {
    decimals: number;
    name: string;
    symbol: string;
  };
  shortName: string;
};

export type StargateSupportedToken = {
  chainKey: string;
  decimals: number;
  isBridgeable: boolean;
  price: {
    currency: string;
    value: number;
  };
  symbol: string;
};

export type FreeTechSupportedRoute = {
  chainId: string;
  id: string;
  name: string;
  tokens: Array<{
    id: string;
    decimals: number;
  }>;
};

export type SupportedRoute = {
  chainId: number;
  token: string;
};

export enum BridgeView {
  WITHDRAW = 'withdraw',
  DEPOSIT = 'deposit',
}

export type BridgeSupportedRoutes = {
  [BridgeView.WITHDRAW]: SupportedRoute[];
  [BridgeView.DEPOSIT]: SupportedRoute[];
};
