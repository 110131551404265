'use client';

import { useBridgeContext, Source } from '@/BridgeProvider';
import localConfig from '@/config';
import { NetworkOption, L1_NETWORKS, L2_NETWORKS } from '@/constants/networks';
import { NETWORK_TOKENS } from '@/constants/tokens';
import { Token } from '@/types/token';
import { useCallback, useMemo } from 'react';
import { TokenSelectorDropdown, BridgeAmountInput } from '@/components/Bridge';
import { useLocalizedContentfulStrings } from '@/hooks/content';
import { twMerge } from 'tailwind-merge';
import { usePathname, useRouter } from 'next/navigation';
import { useThirdPartyOnlyBridge } from '@/hooks/bridge/useThirdPartyOnlyBridge';
import { BridgeView } from '@/utils/thirdPartyBridges/types';

export const TokenSelector = ({ source }: { source: Source }) => {
  const pathname = usePathname();
  const isCanonicalBridge = pathname.includes('/bridge/canonical');
  const { state, dispatch } = useBridgeContext();
  const isDeposit = state.view === BridgeView.DEPOSIT;
  const selectedNetworkId = state[source].network;
  const { localize } = useLocalizedContentfulStrings();
  const { isNetworkThirdPartyOnly, isTokenThirdPartyOnly } = useThirdPartyOnlyBridge(source);
  const router = useRouter();
  const selectedToken = state[source].token;

  const handleTokenSelect = useCallback(
    (option: Token) => {
      router.push(`${pathname}?token=${option.symbol}&network=${selectedNetworkId}`);
      dispatch({
        type: 'SET_SELECTED_TOKEN',
        payload: option,
        source,
      });
    },
    [dispatch, router, selectedNetworkId, pathname, source]
  );

  const handleNetworkSelect = useCallback(
    (option: NetworkOption) => {
      router.push(`${pathname}?token=${selectedToken?.symbol}&network=${option.chainId}`);
      dispatch({
        type: 'SET_SELECTED_NETWORK',
        payload: option.chainId,
        source,
      });
    },
    [dispatch, router, selectedToken?.symbol, pathname, source]
  );

  const tokensOptions = useMemo(() => {
    const neededChainId = isDeposit ? +localConfig.l1ChainId : +localConfig.l2ChainId;
    const networkId = NETWORK_TOKENS[selectedNetworkId] ? selectedNetworkId : neededChainId;

    let options = NETWORK_TOKENS[networkId];

    if (isCanonicalBridge) {
      options = options.filter((token) => !isTokenThirdPartyOnly(token.symbol));
    }

    return options
      .filter((token) => !isDeposit || !token.hideFromDeposit)
      .map((token) => ({
        label: token.symbol,
        icon: token.icon,
        value: token,
        bridge: token.bridge,
      }));
  }, [selectedNetworkId, isDeposit, isCanonicalBridge, isTokenThirdPartyOnly]);

  const networkOptions = useMemo(() => {
    const options = isDeposit ? L1_NETWORKS : L2_NETWORKS;

    if (isCanonicalBridge) {
      return options
        .filter((network) => !isNetworkThirdPartyOnly(network.chainId))
        .map((item) => ({
          label: item.name,
          icon: item.icon,
          value: item,
        }));
    }

    return options.map((item) => ({
      label: item.name,
      icon: item.icon,
      value: item,
    }));
  }, [isDeposit, isCanonicalBridge, isNetworkThirdPartyOnly]);

  const selectedTokenOption = useMemo(
    () =>
      selectedToken
        ? tokensOptions.find((_option) => selectedToken.contractAddress === _option.value.contractAddress)
        : undefined,
    [selectedToken, tokensOptions]
  );

  const selectedNetwork = useMemo(
    () => networkOptions.find((_option) => state[source].network === _option.value.chainId),
    [networkOptions, state, source]
  );

  return (
    <div className="flex flex-col sm:flex-row gap-2 relative">
      <TokenSelectorDropdown
        hideSelector={!!state.inputValue && isCanonicalBridge}
        options={tokensOptions}
        label={isCanonicalBridge ? localize('bridge') : isDeposit ? localize('get') : localize('withdraw')}
        selectedValue={selectedTokenOption}
        onSelect={handleTokenSelect}
      >
        {isCanonicalBridge && <BridgeAmountInput />}
      </TokenSelectorDropdown>
      <TokenSelectorDropdown
        className={twMerge(isCanonicalBridge && 'sm:max-w-[200px]')}
        optionClassName="uppercase"
        options={networkOptions}
        label={isDeposit ? localize('from') : localize('to')}
        selectedValue={selectedNetwork}
        onSelect={handleNetworkSelect}
      />
    </div>
  );
};
