export const InitiateWithdrawalIcon = ({ className, size = 24 }: { className?: string; size?: number }) => {
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4 4.03613L20.927 12.4997L4 20.9632V4.03613ZM5.5 6.46318V18.5361L17.5729 12.4997L5.5 6.46318Z"
        fill="currentColor"
      />
    </svg>
  );
};
