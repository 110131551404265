const SUPPORTED_TOKEN_ICONS = {
  eth: '/cryptoIcons/eth.svg',
  weth: '/cryptoIcons/weth.svg',
  wbtc: '/cryptoIcons/wbtc.svg',
  usdt: '/cryptoIcons/usdt.svg',
  usdc: '/cryptoIcons/usdc.svg',
} as const;

const SUPPORTED_NETWORK_ICONS = {
  1: '/assets/icons/eth_light.svg',
  10: '/assets/icons/optimism.svg',
  8453: '/assets/icons/base.svg',
  42161: '/assets/icons/arbitrum.svg',
} as const;

export const getSupportedTokenIcon = (symbol: string) => {
  return SUPPORTED_TOKEN_ICONS[symbol.toLowerCase() as keyof typeof SUPPORTED_TOKEN_ICONS] || '/cryptoIcons/token.svg';
};

export const getSupportedNetworkIconByChainId = (chainId: number) => {
  return SUPPORTED_NETWORK_ICONS[chainId as keyof typeof SUPPORTED_NETWORK_ICONS] || '';
};
