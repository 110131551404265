'use client';

import { SectionTitle } from './SectionTitle';
import { useLocalizedContentfulStrings } from '@/hooks/content';
import Link from 'next/link';

export const SupportSection = () => {
  const { localize } = useLocalizedContentfulStrings();

  return (
    <div className="flex flex-col gap-y-2">
      <SectionTitle>{localize('support')}</SectionTitle>
      <p className="font-sans text-sm text-neutral-100">
        {localize('bridgeSupportDescPart1')}{' '}
        <Link
          className="underline"
          href="https://discord.com/invite/modenetworkofficial"
          target="_blank"
          title="Mode Discord"
        >
          Discord
        </Link>{' '}
        {localize('bridgeSupportDescPart2')}
      </p>
    </div>
  );
};
