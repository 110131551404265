// Otherwise we cannot access process.env var using dynamic name in getEnvVar
const envVars = {
  NEXT_PUBLIC_SFS_BASE_URL: process.env.NEXT_PUBLIC_SFS_BASE_URL,
  NEXT_PUBLIC_FEE_SHARING_CONTRACT_ADDRESS: process.env.NEXT_PUBLIC_FEE_SHARING_CONTRACT_ADDRESS,
  NEXT_PUBLIC_BACKEND_API_URL: process.env.NEXT_PUBLIC_BACKEND_API_URL,
  NEXT_PUBLIC_EXPLORER_URL: process.env.NEXT_PUBLIC_EXPLORER_URL,
  NEXT_PUBLIC_ETHERSCAN_EXPLORER_URL: process.env.NEXT_PUBLIC_ETHERSCAN_EXPLORER_URL,
  NEXT_PUBLIC_ETH_DEPOSIT_GAS_LIMIT: process.env.NEXT_PUBLIC_ETH_DEPOSIT_GAS_LIMIT,
  NEXT_PUBLIC_TOKEN_DEPOSIT_GAS_LIMIT: process.env.NEXT_PUBLIC_TOKEN_DEPOSIT_GAS_LIMIT,
  NEXT_PUBLIC_POSTHOG_HOST: process.env.NEXT_PUBLIC_POSTHOG_HOST,
  NEXT_PUBLIC_APP_URL: process.env.NEXT_PUBLIC_APP_URL,
  NEXT_PUBLIC_SHOW_LANGUAGE_SWITCHER: process.env.NEXT_PUBLIC_SHOW_LANGUAGE_SWITCHER,
  NEXT_PUBLIC_BOOSTED_REWARD_PERIOD: process.env.NEXT_PUBLIC_BOOSTED_REWARD_PERIOD,
  NEXT_PUBLIC_SHOW_MAINTENANCE_INFO: process.env.NEXT_PUBLIC_SHOW_MAINTENANCE_INFO,
  NEXT_PUBLIC_CONTENTFUL_URL: process.env.NEXT_PUBLIC_CONTENTFUL_URL,
  NEXT_PUBLIC_SHOW_CONTENT_LIVE: process.env.NEXT_PUBLIC_SHOW_CONTENT_LIVE,
  NEXT_PUBLIC_CONTENTFUL_SPACE: process.env.NEXT_PUBLIC_CONTENTFUL_SPACE,
  NEXT_PUBLIC_CONTENTFUL_TOKEN: process.env.NEXT_PUBLIC_CONTENTFUL_TOKEN,
  NEXT_PUBLIC_CONTENTFUL_HOST: process.env.NEXT_PUBLIC_CONTENTFUL_HOST,
  NEXT_PUBLIC_SHOW_AI_PAGE: process.env.NEXT_PUBLIC_SHOW_AI_PAGE,
  NEXT_PUBLIC_SHOW_AI_TERMINAL_PAGE: process.env.NEXT_PUBLIC_SHOW_AI_TERMINAL_PAGE,
  NEXT_PUBLIC_SHOW_CLAIM_OP_BUTTON: process.env.NEXT_PUBLIC_SHOW_CLAIM_OP_BUTTON,
  NEXT_PUBLIC_MODE_ENV: process.env.NEXT_PUBLIC_MODE_ENV,
  NEXT_PUBLIC_BRIDGE_ENV: process.env.NEXT_PUBLIC_BRIDGE_ENV,
  NEXT_PUBLIC_L1_BRIDGE_ADDRESS: process.env.NEXT_PUBLIC_L1_BRIDGE_ADDRESS,
  NEXT_PUBLIC_L1_CHAIN_ID: process.env.NEXT_PUBLIC_L1_CHAIN_ID,
  NEXT_PUBLIC_L1_RPC: process.env.NEXT_PUBLIC_L1_RPC,
  NEXT_PUBLIC_L2_BRIDGE_ADDRESS: process.env.NEXT_PUBLIC_L2_BRIDGE_ADDRESS,
  NEXT_PUBLIC_L2_CHAIN_ID: process.env.NEXT_PUBLIC_L2_CHAIN_ID,
  NEXT_PUBLIC_L2_RPC: process.env.NEXT_PUBLIC_L2_RPC,
  NEXT_PUBLIC_WHITELISTED_TOKENS: process.env.NEXT_PUBLIC_WHITELISTED_TOKENS,
  NEXT_PUBLIC_BRIDGE_TESTNET_URL: process.env.NEXT_PUBLIC_BRIDGE_TESTNET_URL,
  NEXT_PUBLIC_BRIDGE_MAINNET_URL: process.env.NEXT_PUBLIC_BRIDGE_MAINNET_URL,
  NEXT_PUBLIC_MODE_DROP_SUBGRAPH_URL: process.env.NEXT_PUBLIC_MODE_DROP_SUBGRAPH_URL,
  NEXT_PUBLIC_NFT_FACTORY_ADDRESS: process.env.NEXT_PUBLIC_NFT_FACTORY_ADDRESS,
  NEXT_PUBLIC_LOCK_ADDRESS: process.env.NEXT_PUBLIC_LOCK_ADDRESS,
  NEXT_PUBLIC_MODE_TOKEN_ADDRESS: process.env.NEXT_PUBLIC_MODE_TOKEN_ADDRESS,
  NEXT_PUBLIC_SHOW_LOCK_SECTION: process.env.NEXT_PUBLIC_SHOW_LOCK_SECTION,
  NEXT_PUBLIC_LOCK_MODE_USDC_BPT_ADDRESS: process.env.NEXT_PUBLIC_LOCK_MODE_USDC_BPT_ADDRESS,
  NEXT_PUBLIC_MODE_USDC_BPT_TOKEN_ADDRESS: process.env.NEXT_PUBLIC_MODE_USDC_BPT_TOKEN_ADDRESS,
  NEXT_PUBLIC_CREATE_LOCK_ADDRESS: process.env.NEXT_PUBLIC_CREATE_LOCK_ADDRESS,
  NEXT_PUBLIC_CREATE_LOCK_MODE_USDC_BPT_ADDRESS: process.env.NEXT_PUBLIC_CREATE_LOCK_MODE_USDC_BPT_ADDRESS,
  NEXT_PUBLIC_SHOW_OP_BANNER: process.env.NEXT_PUBLIC_SHOW_OP_BANNER,
  NEXT_PUBLIC_PAGE_TITLE: process.env.NEXT_PUBLIC_PAGE_TITLE,
  NEXT_PUBLIC_MERKL_API: process.env.NEXT_PUBLIC_MERKL_API,
};

const getEnvVar = (name: keyof typeof envVars): string => {
  const value = envVars[name];
  if (!value) {
    throw new Error(`Environment variable ${name} is missing!`);
  }
  return value;
};

const localConfig = {
  sfsBaseUrl: getEnvVar('NEXT_PUBLIC_SFS_BASE_URL'),
  feeSharingContractAddress: getEnvVar('NEXT_PUBLIC_FEE_SHARING_CONTRACT_ADDRESS'),
  apiUrl: getEnvVar('NEXT_PUBLIC_BACKEND_API_URL'),
  explorerUrl: getEnvVar('NEXT_PUBLIC_EXPLORER_URL'),
  etherscanExplorerUrl: getEnvVar('NEXT_PUBLIC_ETHERSCAN_EXPLORER_URL'),
  ethDepositGasLimit: getEnvVar('NEXT_PUBLIC_ETH_DEPOSIT_GAS_LIMIT'),
  tokenDepositGasLimit: getEnvVar('NEXT_PUBLIC_TOKEN_DEPOSIT_GAS_LIMIT'),
  posthogHost: getEnvVar('NEXT_PUBLIC_POSTHOG_HOST'),
  appUrl: getEnvVar('NEXT_PUBLIC_APP_URL'),
  boostedRewardsPeriod: getEnvVar('NEXT_PUBLIC_BOOSTED_REWARD_PERIOD'),
  contentfulUrl: getEnvVar('NEXT_PUBLIC_CONTENTFUL_URL'),
  showContentLive: getEnvVar('NEXT_PUBLIC_SHOW_CONTENT_LIVE') === 'true',
  contentfulSpace: getEnvVar('NEXT_PUBLIC_CONTENTFUL_SPACE'),
  contentfulToken: getEnvVar('NEXT_PUBLIC_CONTENTFUL_TOKEN'),
  contentfulHost: getEnvVar('NEXT_PUBLIC_CONTENTFUL_HOST'),

  // feature flags
  showAIPage: getEnvVar('NEXT_PUBLIC_SHOW_AI_PAGE'),
  showAITerminalPage: getEnvVar('NEXT_PUBLIC_SHOW_AI_TERMINAL_PAGE'),
  showClaimOPButton: getEnvVar('NEXT_PUBLIC_SHOW_CLAIM_OP_BUTTON'),
  showLanguageSwitcher: getEnvVar('NEXT_PUBLIC_SHOW_LANGUAGE_SWITCHER'),
  showLockSection: getEnvVar('NEXT_PUBLIC_SHOW_LOCK_SECTION'),
  showMaintenanceInfo: getEnvVar('NEXT_PUBLIC_SHOW_MAINTENANCE_INFO'),
  showOPBanner: getEnvVar('NEXT_PUBLIC_SHOW_OP_BANNER'),

  modeEnv: getEnvVar('NEXT_PUBLIC_MODE_ENV'),
  bridgeEnv: getEnvVar('NEXT_PUBLIC_BRIDGE_ENV'),

  // L1
  l1BridgeAddress: getEnvVar('NEXT_PUBLIC_L1_BRIDGE_ADDRESS'),
  l1ChainId: getEnvVar('NEXT_PUBLIC_L1_CHAIN_ID'),
  l1Rpc: getEnvVar('NEXT_PUBLIC_L1_RPC'),

  // L2
  l2BridgeAddress: getEnvVar('NEXT_PUBLIC_L2_BRIDGE_ADDRESS'),
  l2ChainId: getEnvVar('NEXT_PUBLIC_L2_CHAIN_ID'),
  l2Rpc: getEnvVar('NEXT_PUBLIC_L2_RPC'),

  // Tokens
  whitelistedTokens: envVars.NEXT_PUBLIC_WHITELISTED_TOKENS ? JSON.parse(envVars.NEXT_PUBLIC_WHITELISTED_TOKENS) : null,

  // Bridge URLs
  bridgeTestnetUrl: getEnvVar('NEXT_PUBLIC_BRIDGE_TESTNET_URL'),
  bridgeMainnettUrl: getEnvVar('NEXT_PUBLIC_BRIDGE_MAINNET_URL'),

  // Mode DevDrop subgraph
  modeDropSubgraphUrl: getEnvVar('NEXT_PUBLIC_MODE_DROP_SUBGRAPH_URL'),
  // Mode DevDrop factory address
  nftFactoryAddress: getEnvVar('NEXT_PUBLIC_NFT_FACTORY_ADDRESS'),

  // Lock
  lockAddress: getEnvVar('NEXT_PUBLIC_LOCK_ADDRESS'),
  modeTokenAddress: getEnvVar('NEXT_PUBLIC_MODE_TOKEN_ADDRESS'),

  lockModeUsdcBptAddress: getEnvVar('NEXT_PUBLIC_LOCK_MODE_USDC_BPT_ADDRESS'),
  modeModeUsdcBptTokenAddress: getEnvVar('NEXT_PUBLIC_MODE_USDC_BPT_TOKEN_ADDRESS'),

  // Lock season 3
  createLockAddress: getEnvVar('NEXT_PUBLIC_CREATE_LOCK_ADDRESS'),
  createLockModeUsdcBptAddress: getEnvVar('NEXT_PUBLIC_CREATE_LOCK_MODE_USDC_BPT_ADDRESS'),

  // show custom page title
  pageTitle: getEnvVar('NEXT_PUBLIC_PAGE_TITLE'),

  merklAPI: getEnvVar('NEXT_PUBLIC_MERKL_API'),
};

export default localConfig;
