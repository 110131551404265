export enum ModeSupportedChain {
  ETHERIUM = 1,
  MODE = 34443,
  OPTIMISM = 10,
  BASE = 8453,
  ARBITRUM = 42161,
  POLYGON = 137,
  SEPOLIA = 11155111,
  'MODE TESTNET' = 34444,
}

export const getSuppostedChainIdByName = (chainName: string): number | undefined => {
  const chainNames = Object.keys(ModeSupportedChain);
  for (const name of chainNames) {
    if (chainName.toLowerCase().includes(name.toLowerCase())) {
      return ModeSupportedChain[name as keyof typeof ModeSupportedChain];
    }
  }
  return undefined;
};

export const isChainSupportedByMode = (chainId: number): boolean => {
  return Object.values(ModeSupportedChain).includes(chainId);
};
