'use client';

import { useState } from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import clsx from 'clsx';
import { useLocalizedContentfulStrings } from '@/hooks/content';

export const CopyFrom = ({
  copyValue,
  variant = 'secondary',
  text,
  compact = true,
}: {
  copyValue: string;
  variant?: 'primary' | 'secondary';
  text?: string;
  compact?: boolean;
}) => {
  const [isCopyFrom, setIsCopyFrom] = useState(false);
  const { localize } = useLocalizedContentfulStrings();

  if (isCopyFrom) {
    setTimeout(() => {
      setIsCopyFrom(false);
    }, 2500);
  }

  return (
    <div
      className={clsx(
        compact ? 'p-1 justify-between' : 'p-2 gap-x-2',
        'flex items-center rounded-lg border border-yellow-500 min-w-[215px]'
      )}
      onClick={(e) => e.stopPropagation()}
    >
      {!compact && (
        <p className="text-xs uppercase font-medium text-neutral-100 whitespace-nowrap mr-2">
          {localize('referYourFriendToMoveUp')}
        </p>
      )}
      <span className={clsx(variant === 'primary' ? 'text-yellow-500' : 'text-neutral-100', 'py-1 pl-1 text-xs')}>
        {text ? text : copyValue}
      </span>

      <CopyToClipboard
        text={copyValue}
        onCopy={() => {
          setIsCopyFrom(true);
        }}
      >
        <div className="rounded flex p-1 items-center justify-center w-6 h-6 bg-yellow-500">
          {isCopyFrom ? (
            <span>
              <svg width="14" height="12" viewBox="0 0 14 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M13.7 1.19999C13.3 0.799994 12.7 0.799994 12.3 1.19999L4.8 8.69999L1.7 5.59999C1.3 5.19999 0.7 5.19999 0.3 5.59999C-0.1 5.99999 -0.1 6.59999 0.3 6.99999L4.1 10.8C4.3 11 4.5 11.1 4.8 11.1C5.1 11.1 5.3 11 5.5 10.8L13.7 2.59999C14.1 2.19999 14.1 1.59999 13.7 1.19999Z"
                  fill="#000000"
                />
              </svg>
            </span>
          ) : (
            <span className="cursor-pointer">
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                <path
                  d="M10.6667 13.334H5.33333C4.8029 13.334 4.29419 13.1233 3.91912 12.7482C3.54405 12.3731 3.33333 11.8644 3.33333 11.334V4.66732C3.33333 4.49051 3.2631 4.32094 3.13807 4.19591C3.01305 4.07089 2.84348 4.00065 2.66667 4.00065C2.48986 4.00065 2.32029 4.07089 2.19526 4.19591C2.07024 4.32094 2 4.49051 2 4.66732V11.334C2 12.218 2.35119 13.0659 2.97631 13.691C3.60143 14.3161 4.44928 14.6673 5.33333 14.6673H10.6667C10.8435 14.6673 11.013 14.5971 11.1381 14.4721C11.2631 14.347 11.3333 14.1775 11.3333 14.0007C11.3333 13.8238 11.2631 13.6543 11.1381 13.5292C11.013 13.4042 10.8435 13.334 10.6667 13.334Z"
                  fill="#000000"
                />
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M6.66667 1.33398C5.56217 1.33398 4.66667 2.22917 4.66667 3.33398V10.0007C4.66667 11.1055 5.56217 12.0007 6.66667 12.0007H12C13.1045 12.0007 14 11.1055 14 10.0007V3.33398C14 2.22917 13.1045 1.33398 12 1.33398H6.66667ZM6.66667 2.66732C6.2985 2.66732 6 2.96549 6 3.33398V10.0007C6 10.3691 6.2985 10.6673 6.66667 10.6673H12C12.3682 10.6673 12.6667 10.3691 12.6667 10.0007V3.33398C12.6667 2.96549 12.3682 2.66732 12 2.66732H6.66667Z"
                  fill="#000000"
                />
              </svg>
            </span>
          )}
        </div>
      </CopyToClipboard>
    </div>
  );
};
